@import './../../../base.scss';

.dropDownItem{
  display: block;
  width: 100%;
  button{    
    display: block;
    width: 100%;
    padding: 0px 8px;
    border: 0px;
    outline: none;
    background-color: transparent;
    text-align: left;
    white-space: nowrap;
    @include border-radius(0px);
    @include box-shadow(0px);
    @include font(0.875rem,2.25rem, $dropDownItem, null, null);
    &:hover{
      background-color: $dropDownItemHover;
    }
  }
  a{    
    display: block;
    width: 100%;
    padding: 0px 8px;
    outline: none;
    background-color: transparent;
    @include font(0.875rem,2.25rem, $dropDownItem, null, null);
    text-align: left;
    &:hover{
      background-color: $dropDownItemHover;
    }
  }
}