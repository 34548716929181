@import "./../../../base.scss";

.usersListSelectedItem {
  cursor: pointer;

  .container_usersListItemWrapper {
    position: relative;
    overflow: hidden;
    padding: 5px;
    @include box-shadow(inset 0px 1px 0px 0px $employeeItemBorderColor);
  }

  .mDEmployeeItems {
    //   padding: 27px 10px 20px 38px;
    padding: 2px 2px 2px 38px;
    .container_employeeInfoLink {
      display: flex;
      justify-content: flex-end;
    }

    .mDEmployeeItem {
      & + .mDEmployeeItem {
        margin-top: 1px;
      }

      .mDEmployeeItemTitle {
        display: block;
        @include font(0.83rem, 1.25rem, $employeeTitle, 600, null);
      }

      .mDEmployeeItemContent {
        display: block;
        margin-top: 1px;
        @include font(0.75rem, 1.25rem, $formInputContentTitle, 600, null);
      }
    }
  }
}
