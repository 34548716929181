@import './../../../base.scss';

.buttonIcon {
  display: flex;
  padding: 0px;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0px;
  outline: none;
  @include font(0.875rem, 1, $buttonIconColor, null, null);
  cursor: pointer;
  opacity: 1;
  @include transition(opacity $animateTransition);

  &:not(:disabled):hover {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
  }

  .wrapp {
    display: flex;
    align-items: center;
  }
}