@import "./../base.scss";

@import "./items/contols/buttonMediumPrimary.module.scss";
@import "./filterEmployeesTooltip.module.scss";

.viewTableHead {
  background-color: $tableHead;
  display: flex;
  justify-content: flex-start;
  border-bottom: solid 1px $tableBorder;
  font-size: 0.7rem;
  font-weight: 800;
  text-transform: uppercase;

  &:hover {
    background-color: $tableContentHover;
    @include box-shadow(inset 2px 0px 0px 0px $color1-a);

    .container_tableContentRowItem {
      & > *:not(button) {
        opacity: 1;
      }

      button {
        display: inline-block;
        @extend .buttonMediumPrimary;
      }
    }
  }

  .container_tableHeadItem {
    display: block;

    &:nth-child(1) {
      min-width: 120px;
      padding-left: 35px;
    }

    &:nth-child(2) {
      min-width: 110px;
    }

    &:nth-child(3) {
      min-width: 170px;
    }

    &:nth-child(4) {
      min-width: 60px;
    }

    &:nth-child(5) {
      width: 100%;
    }

    &:nth-child(6) {
      min-width: 130px;
    }
  }

  .container_tableHeadItem_objective {
    display: block;
    width: 140px;

    &:nth-child(1) {
      width: 120px;
      margin-left: 30px;
    }

    &:nth-child(6) {
      width: auto;
    }
  }
}
/* @import "./../base.scss";

.viewTableHead {
  background-color: $tableHead;
  display: flex;

  align-items: stretch;
  justify-content: flex-start;
  border-bottom: solid 1px $tableBorder;

  .container_tableHeadItem {
    display: block;
    // vertical-align: middle;
    text-align: left;
    padding: 5px 10px;
    @include font(0.75rem, 0.9375rem, $tableHeadCell, null, null);
    text-transform: uppercase;

    &:nth-child(1) {
      width: 135px;
      white-space: wrap;
      padding-left: 35px;
    }

    &:nth-child(2) {
      width: 120px;
    }

    &:nth-child(3) {
      width: 700px;
      white-space: wrap;
    }

    &:nth-child(4) {
      width: 170px;
      white-space: wrap;
    }

    &:nth-child(5) {
      width: 50px;
    }

    &:nth-child(6) {
      width: 170px;
    }

    &:nth-child(7) {
      flex-grow: 1;
      text-align: left;
    }
  }

  .container_tableHeadItem_responsive {
    @extend .container_tableHeadItem;
    width: 200px !important;
    flex-grow: 1;
    flex-basis: 200px;
    max-width: 350px;

    &:last-child {
      margin-left: auto;
      width: 138px !important;
      flex-grow: 0;
    }
  }
}

@media (max-width: 1700px) {
  .viewTableHead {
    .container_tableHeadItem {
      padding: 15px 10px;

      &:nth-child(1) {
        width: 145px;
      }
  
      &:nth-child(2) {
        width: 100px;
      }
  
      &:nth-child(3) {
        width: 350px;
      }
  
      &:nth-child(4) {
        width: 140px;
      }
  
      &:nth-child(5) {
        width: 100px;
      }
  
      &:nth-child(6) {
        flex-grow: 1;
        text-align: left;
      }
    }

    .container_tableHeadItem_responsive {
      width: 100px;
      flex-basis: 100px;
      max-width: auto;

      &:nth-child(1) {
        width: 150px;
        max-width: 150px;
      }

      &:nth-child(2) {
        width: 150px;
        max-width: 150px;
      }

      &:nth-child(3) {
        width: 150px;
      }

      &:nth-child(4) {
        width: 140px;
        max-width: 140px;
      }

      &:nth-child(5) {
        width: calc(100% - 150px - 150px - 150px - 140px - 130px);
      }

      &:nth-child(6) {
        width: 130px;
        text-align: left;
      }

      &:last-child {
        flex-grow: 0;
        width: 138px !important;
        flex-basis: 138px;
      }
    }
  }
}
 */
