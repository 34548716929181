@import './../../base.scss';

.signInWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 100px 0px;

  .signInForm {
    width: 400px;
    padding: 50px;
    background-color: $color-white;
    @include transition(all $animateTransition);
    @include box-shadow($shadow-level-3);
    @include border-radius(5px);

    // .remember-container {
    //   margin-top: 20px;
    // }

    .button_container {
      margin-top: 40px;

      button {
        width: 100%;
        max-width: 100%;
        text-align: center;
        justify-content: center;

        * {
          justify-content: center;
        }
      }
    }
  }

  .logo_container {
    margin-bottom: 50px;
    text-align: center;
  }
}