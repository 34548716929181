@import './../../../base.scss';

.notification {
  padding: 10px !important;
  margin-top:  30px;
  max-width: 500px;
  @include font(12px, 14px, null, 500, 0);
  @include border-radius(5px);
  display: flex;
  align-items: center;

  .container_icon {
    margin-right: 10px;
    @include font(18px, 1, null, null, null);
  }
}

.notification_error {
  @extend .notification;
  color: $color2-b;
  background-color: $color2-c;
}

.notification_success {
  @extend .notification;
  color: $color3-b;
  background-color: $color3-c;
}

.notification_warning {
  @extend .notification;
  color: $color4-a;
  background-color: $color4-c;
}