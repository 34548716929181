@import './../../../base.scss';

.employeeDevicesMain {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  min-height: 150px;

  .container_deviceItem {
    padding: 3px;
    width: 33.3%;
  }

  .container_loader,
  .container_message {
    width: 100%;
    min-height: 100%;
  }

  .container_loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container_message { 
    padding: 23px 39px;
    max-width: 300px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 1700px) {
  .employeeDevicesMain {
    .container_deviceItem {
      padding: 4px;
    }
  }
}