.alarmButtonContainer {
    margin: 0px;
}

.btn-danger {
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid rgb(0 0 0 / 0%);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: rgb(255 255 255);
    background-color: rgb(200 35 51);
    border-color: rgba(189, 33, 49, 1) !important;
    cursor: pointer;
    text-decoration: none;
    opacity: 1 !important;
}

.geozoneControlContainer {
    margin: 0px;
    padding: 10px 10px;
    background: black;
    color: white;
    border-radius: 0.55rem;
    opacity: 0.9;
    max-width: 490px !important;
}

.geozoneListContainer {
    margin: 0px;
    padding: 10px 10px;
    background: black;
    color: white;
    border-radius: 0.55rem;
    opacity: 0.9;
}

.geozoneListContainer li {
    cursor: pointer;
    font-size: 0.93rem;
    font-weight: 500;
}

.geozoneListContainer li:hover {
    color: #cfd1eb;
    /* Цвет ссылки при наведении */
    text-shadow: #ffffff 10px 10 10px;
}

.parametrsDiv {
    display: block;
    margin: 0px;
    margin-right: 25px;
    margin-right: 15px;
}

.customLeafletControl p {
    display: block;
    margin-bottom: 0px !important;
    text-transform: uppercase;
    font-weight: 600;
    text-shadow: rgb(0, 17, 255) 1px 0 5px;
}

.containerParametrs {
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    color: white;
    font-size: 1em;
    opacity: 0.9;
}

.closeButtonDiv {
    display: block;
    text-align: right;
    padding: 10px;
}

.divContentGeoZone {
    display: flex;
    padding: 10px 10px;
}

.closeButton {
    border: none;
    background: none;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
}

.inputTitleDiv {
    display: block;
    margin-bottom: 10px;
}

.inputTitleDiv input {
    width: 100%;
}

.customLeafletControl label {
    margin-left: 10px;
    padding-bottom: 5px;
}

.colorPicker {
    padding: 0px;
    margin: 0px;
    border: none;
    background: black;
    border-radius: 0.75rem;
    width: 27px;
    margin-bottom: 10px;
}

.opacityDiv {
    margin-bottom: 10px;
}

.opacityDiv label {
    margin: 0px;
    display: block;
    margin-bottom: 5px;
}

.opacityPicker {
    width: 100%;
}

.listProperies {
    margin-bottom: 10px;
}

.listProperies li:last-chid() {
    margin: 0px;
    padding: 0px;
}

.section::-webkit-scrollbar {
    width: 10px;
}

.section::-webkit-scrollbar-track {
    background-color: none;
}

.box {
    max-height: 450px;
    overflow-y: auto;
}

.checkboxDiv {
    padding: 0px;
    margin-bottom: 5px;    
}

.checkboxDiv input {
    padding: 0px !important;
    margin:  0px !important;  
}

.css-s7d5x3-MuiButtonGroup-root .MuiButtonGroup-grouped {
    background-color: #ffffff!important;
    min-width: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important;
    border: none;
    border-radius: 5px;
    padding: 0px;
}

.MuiButtonGroup-root .MuiButtonGroup-grouped {
    background-color: #ffffff!important;
    min-width: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important;
    border: none;
    border-radius: 5px;
    padding: 0px;
}