@import "./../../../base.scss";

.employeesMainDevicesWrapper {
  position: relative;
  z-index: 2;

  .container_defaultSide {
    width: 675px;
  }

  .container_moreSide {
    position: absolute;
    z-index: 2;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: stretch;
    @include transition(all $animateTransition);
  }

  .baseSize {
    @extend .container_moreSide;
    height: 470px;
    width: calc(100% - 675px);
    @include border-radius-top-right(4px);
    @include border-radius-bottom-right(4px);
  }

  .fullWidth {
    @extend .container_moreSide;
    height: 470px;
    width: 100%;
    @include border-radius(5px);
  }

  .fullSize {
    @extend .container_moreSide;
    height: calc(100vh - 162px);
    width: 100%;
    @include border-radius(5px);
  }

  .container_controls {
    position: absolute;
    z-index: 3;
    right: 10px;
    top: 10px;
    display: flex;

    .container_controlItem {
      & + * {
        margin-left: 10px;
      }

      .icon_full_width,
      .icon_default_width {
        font-size: 0.8rem;
      }
    }
  }
}

@media (max-width: 1700px) {
  .employeesMainDevicesWrapper {
    .container_defaultSide {
      width: 50%;
    }

    .baseSize {
      width: 50%;
    }
  }
}
