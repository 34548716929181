@import './../base.scss';

.controlCountOnPage{
  display: flex;
  align-items: center;
  @include font(0.875rem, 1.1875rem, $countOnlist, null, null);
  .title{
    & + *{
      margin-left: 3px;
    }
  }
}