@import "./../base.scss";

.tooltipTemplate {
  box-sizing: border-box;
  font-size: 0.75rem !important;
  padding: 15px !important;
  background-color: $tooltipBack !important;
  @include border-radius(2px);
  // @include font(0.75rem, 0.875rem, $tooltipColorTitle, null, null);

  h4 {
    margin-top: 7px !important;
  }

  li {
    display: list-item !important;
    padding: 4px 0px !important;

    & + li {
      border-top: solid 1px $tooltipDash !important;
    }
  }

  .titleTooltip {
    font-family: $font1 !important;
    @include font(0.75rem, 0.875rem, $tooltipColorTitle, 600, null);
  }

  .contentTooltip {
    margin-left: 35px !important;
    align-content: inherit !important;
    font-family: $font1 !important;
    @include font(0.75rem, 0.875rem, $tooltipColorCont, null, null);
    max-width: 450px !important;
  }
}
