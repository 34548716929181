@import "./../base.scss";

.wrapper {
  .content {
    display: flex;
    background-color: $back; 

    .container_sideBar {
      width: $sideWidth;
      min-width: $sideWidth;
      @include transform(translateX(0));
      @include transition(transform $animateTransition);
      z-index: 1;
      position: relative;
    }

    .container_sideContent {
      min-width: $contentWidth;
      height: $minHeightSides;
      width: 100%;
      margin-right: auto;
      @include transform(translateX(0));
      $trans: min-width $animateTransition, width $animateTransition, transform $animateTransition;
      @include transition($trans);
      @include customScrollContent;
    }

    .container_secondSideBar {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      @include transform(translateX(0));
      @include transition(left $animateTransition);
    }
  }

  .content_closedBar {
    @extend .content;

    .container_sideBar {
      @include transform(translateX(-$sideWidth + 65px));
    }

    .container_secondSideBar {
      @include transform(translateX(-$sideWidth));
    }

    .container_sideContent {
      width: calc(100% - 65px);
      min-width: calc(100% - 65px);
      @include transform(translateX(-$sideWidth + 65px));
      $trans: min-width $animateTransition, width $animateTransition, transform $animateTransition;
      @include transition($trans);
    }
  }
}

@media (max-width: 1700px) {
  .wrapper {
    background-image: none;
    .content {
      .container_sideBar {
        width: 320px;
        min-width: 320px;
      }

      .container_sideContent {
        min-width: calc(100% - 320px);
        min-height: 538px;
      }
    }

    .content_closedBar {
      .container_sideBar {
        @include transform(translateX(-255px));
      }

      .container_sideContent {
        min-width: calc(100% - 65px);
        @include transform(translateX(-255px));
      }
    }
  }
}
