@import './../base.scss';

.Collapsible{
  .Collapsible__trigger{
    &.is-open{

    }
    &.is-close{

    }
  }
  .Collapsible__contentOuter{
    .Collapsible__contentInner{
      padding-top: 20px;
    }
  }
}