.notificationHeder_container {
  position: relative;
  display: inline;
  z-index: 10;
  cursor: pointer;

  .MuiButtonBase-root {
    display: flex;
    justify-content: start;
  }
}

.notificationHeder_number {
  position: absolute;
  right: 0px;
  background-color: rgb(255 80 70);
  color: white;
  display: inline;
  padding: 2px;
  margin: 0px;
  border-radius: 50px;
  font-size: 0.6rem;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  min-width: 19px;
}

.notificationHeder_menu {
  background-color: white;
  position: fixed;
  margin-left: -430px;
  width: 430px !important;
  border-radius: 8px;
  box-shadow: -5px 5px 15px rgba(89, 90, 94, 1) !important;
  vertical-align: middle;
}

.notificationHeder_settings {
  margin: 0px 10px !important;
  padding: 0px !important;
  box-shadow: none !important;
  border-radius: 0;
}

.notificationMenu {
  position: absolute;
  margin-top: -40px;
  padding-right: 50px;
}

.notificationHeder_settings_list {
  color: red;
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
  box-shadow: none !important;

  ul li {
    border: none;
    padding: 0px 5px !important;
    margin: 0px !important;
  }

  label {
    margin-left: 5px;
    font-size: 0.75rem;
  }
}

.notificationHeder_settings:hover {
  background-color: white !important;
}

.notificationItem {
  display: flex;
  position: relative;
  padding: 2px 6px;

  cursor: pointer;

  div:nth-child(2) {
    display: flex;

    div:nth-child(1) {
      width: 50%;
    }
  }

  span {
    display: flex !important;
    text-align: left;
    font-size: 0.7rem;
    font-weight: 100;
    margin-left: 5px;
    width: 100%;

    .notificationTitle {
      display: flex;
      margin-left: auto;
      font-size: 0.68rem;
      text-align: right;
      width: auto;
    }
  }
}

.notificationItemView {
  display: flex;
  padding: 2px 12px;
  word-wrap: break-word;
  width: 100%;
  background-color: rgb(247 251 255);
}

.notificationItemView_text {
  font-size: 0.68rem;
  font-weight: 500;
  word-wrap: break-word;
  width: 100%;
}

.notificationClose {
  width: 100%;
  justify-items: end;
  vertical-align: top;
  color: gray;
  margin: 0px 0px 15px 0px;
  padding: 0px;
  margin-left: 5px;
  font-size: 0.1rem;

  span {
    font-weight: 900;
    font-size: 0.75rem;
    color: black;
    vertical-align: top;
    margin-right: 5px;
  }
}

.notificationItem:hover {
  background-color: rgb(247 251 255);
}

.notificationClose:hover {
  color: red;
}

.notification-container {
  padding-right: 200px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 520px;
  padding: 0px 15px;
  max-height: calc(100% - 120px);
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 60px;
  margin-top: 80px;
  overflow: hidden;
}

.notificationsReadAll_container {
  padding: 15px 15px 0px 0px;
  display: flex;
  justify-content: end;
}

.notificationsReadAll_icon:hover {
  cursor: pointer;
}

.notificationsReadAll_icon:hover {
  cursor: pointer;
  color: red;
}
