@import './../../../base.scss';

.container_formItemsInlineGroup {
  display: flex;
  align-items: left;

  .container_formItemInlineGroupItem {
    &+.container_formItemInlineGroupItem {
      margin-left: 30px;
    }
  }
}