@import './../../../base.scss';

.sideBarsWrapper {
  position: relative;
  height: 100%;
}

.container_sideBarInner {
  height: 100%;

  .usersBar {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto 1fr;
  }
}

.usersBar .container_usersList {
  margin-top: 5px;
  height: calc(100vh - 300px);
  width: 100%;
  padding-bottom: 2px;
  // width: calc(100% + 27px);
  // height: calc(100% - 44px);

  .container_preloader {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @include customScrollOuter;
}

@media (max-width: 1700px) {
  .usersBar {
    .container_usersList {
      height: calc(100vh - 332px);
      min-height: 276px;
    }
  }
}