@import './../../../base.scss';

.searchEmployees {
  position: relative;

  .container_searchBtn {
    position: absolute;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;
    width: 26px;
    cursor: pointer;
    @include font(0.75rem, 2.25rem, $buttonSearchEmployees, null, null);
    @include transition(color $animateTransition);

    &:hover {
      color: $textColor;
    }
  }
}