@import "./../../../base.scss";

.usersContainer {
  margin: 0px;
  padding: 0px;
  padding: 25px 25px;
  box-sizing: border-box !important;
  height: 100%;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
}

.usersInnerContainer {
  display: flex;
  background-color: white;
  height: 700px;
  height: 100%;
  border-radius: 4px;
  padding: 15px 25px;  
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
  margin-left: 20px !important;
}

.custom-checkbox {
  display: inline-block;
  width: inherit;
}