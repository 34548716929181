@import "./../base.scss";

.container_sideContentInner {
  display: flex;
  padding: 5px 0px 0px 0px;
  margin-left: 25px;
  
  & + .container_sideContentInner {
    margin-top: 40px;
  }
}

@media (max-width: 1700px) {
  .container_sideContentInner {
 
    & + .container_sideContentInner {

    }
  }
}
