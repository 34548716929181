@import './../../../base.scss';

.container-multiSelect {
  position: relative;

  .container-multiSelectInner__control {
    border: 0px;
    background: none;
    min-height: auto;
    position: relative;
    z-index: 1;
    @include box-shadow(none);

    &[class*='is-focused'] {
      border: 0px;
      @include box-shadow(none);
      background: none;

      &:before {
        border-color: $color1-b;
      }
    }

    &[class*='menu-is-open'] {
      z-index: 2;

      &+.container-multiSelectInner__menu {
        z-index: 2;
      }

      [class*="IndicatorsContainer"] {
        .icon {
          @include transform(rotate(180deg));
        }
      }
    }

    @include border-radius(5px);

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 36px;
      border: solid 1px $inputBorderColor;
      @include border-radius(5px);
    }

    .container-multiSelectInner__value-container {
      position: relative;
      padding-left: 10px;
      padding-right: 10px;
      align-items: center;
      min-height: 36px;
      overflow: unset;

      .container-multiSelectInner__placeholder {
        position: relative;
        top: 0px;
        line-height: 27px;
        @include transform(translateY(0px))
      }

      [class*='Input'] {
        position: absolute;
        top: 0px;
        left: 10px;
        width: 100%;
        height: 100%;
        max-height: 34px;
        padding-right: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        line-height: 30px;

        .container-multiSelectInner__input {
          width: 100% !important;

          input {
            width: 100% !important;
          }
        }
      }

      &[class*='--has-value'] {
        padding-top: 38px;
      }
    }

    .container-multiSelectInner__indicators {
      height: 36px;
      position: relative;
      z-index: 3;

      // .icon {
      //   @include font(0.375rem, 1, $dropDownArrow, null, null)
      // }

      [class*="indicatorSeparator"] {
        display: none;
      }
    }

    [class*='value-container'] {
      [class*="placeholder"] {
        @include font(0.875rem, null, $selectPlaceholder, null, null);
      }
    }

  }

  .container-multiSelectInner__menu {
    position: absolute;
    top: 38px;
    left: 0px;
    width: 100%;
    z-index: 1;
  }
}

// .container-multiSelectInner__control.css-yk16xz-control::before {

//   content: "";
//   display: block;
//   width: calc(100% + 2px);
//   height: 38px;
//   position: absolute;
//   top: -1px;
//   left: -1px;
//   border: solid 1px #DCE0E6;
//   border-radius: 4px;

// }