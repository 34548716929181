@import "./../base.scss";
@import "./../grid.scss";

* {
  @include box-sizing(border-box);

  &:before,
  &:after {
    @include box-sizing(border-box);
  }
}

html {
  min-height: $minHeight;
  position: relative;
}

body {
  font-family: $font1;
  background-color: $color-white;
  background-image: url(/img/background.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px;
  min-width: 1700px;
  margin: 0px auto;
  min-height: 100vh;
  line-height: 1;
  @include font($baseSize, 1.742, $textColor, null, null);
}

.application {
  min-height: 100vh;
  overflow: hidden;
}

.container {
  width: $containerMD;
  padding: $containerPaddingLG;
  margin: 0px auto;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

a {
  text-decoration: none;
}

select,
input:not[type="checkbox"],
textarea {
  width: 100%;
  @include outline(none);
  background: transparent;
  @include box-shadow(none);
  border: none;
  font-family: $font1;
}

span {
  font-family: inherit;
}

button {
  font-family: inherit;
  @include outline(none);
  cursor: pointer;

  &:hover,
  &:focus {
    @include outline(none);
  }
}

.invisLink {
  position: relative;
  z-index: 1;

  a {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 7;
    overflow: hidden;
    text-indent: 200%;
    white-space: nowrap;
  }
}

[class*="iconLeft"] {
  position: relative;
  display: inline-block;

  & > [class*="icon"] {
    display: block;
    position: absolute;
    top: 50%;
    left: 0px;
  }
}

@media (max-width: $maxMediaSM) {
  .container {
    width: $containerSM;
  }
}

@media (max-width: $maxMediaXS) {
  .container {
    width: $containerXS;
    padding: 0px;
  }
}

[class*="btn"] {
  & + [class*="btn"] {
    margin-left: 5px;
  }
}

// default customScroll styles
.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
}

.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}

.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}

.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}

.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.rcs-custom-scroll .rcs-inner-container:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}

.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 3px;
  right: 3px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 3px 0px;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}

.rcs-custom-scroll .rcs-inner-handle {
  height: calc(100% - 12px);
  margin-top: 3px;
  background-color: $paginationScrollBarDefault;
  @include border-radius(2px);
}

//  / default customScroll styles

@media (max-width: 1700px) {
  body {
    min-width: 1366px;
  }
}
