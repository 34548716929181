@import './../../../base.scss';

* {
  &+.pagination:not(.disabled) {
    margin-top: 30px;
  }
}

.pagination {
  display: inline-block;

  &+* {
    margin-top: 30px;
  }

  ul {
    display: flex;
    @include border-radius(5px);

    li {
      background-color: white;
      border-top: solid 1px $paginationBorder;
      border-bottom: solid 1px $paginationBorder;
      // @include transition(all $animateTransition);
      cursor: default;

      &:not(.disabled) {
        cursor: pointer;

        &:hover {
          background-color: $paginationColorActiveBack;
        }
      }

      &:first-child {
        border-left: solid 1px $paginationBorder;
        @include border-radius-top-left(4px);
        @include border-radius-bottom-left(4px);
      }

      &:last-child {
        border-right: solid 1px $paginationBorder;
        @include border-radius-top-right(4px);
        @include border-radius-bottom-right(4px);
      }

      &+li {
        border-left: solid 1px $paginationBorder;
      }

      a {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        @include font(0.875rem, 1, $paginationColor, null, null);
      }

      &.active {
        border-color: $paginationColorActiveBorder;
        background-color: $paginationColorActiveBack;

        &+li {
          border-left: solid 1px $paginationColorActiveBorder;
        }

        a {
          color: $paginationColorActive;
        }
      }

      &.previous {
        a {
          color: $paginationArrow;
        }

        &.disabled {
          a {
            color: $paginationArrowDisabled;
          }
        }
      }

      &.next {
        a {
          color: $paginationArrow;
        }

        &.disabled {
          a {
            color: $paginationArrowDisabled;
          }
        }
      }
    }
  }
}