.sensors-black-popup .leaflet-popup-content-wrapper {
  color: white;
  background: rgba(0, 0, 0, 0.85);
  min-width: 650px;
  font-size: 0.645rem;
  border-radius: .55rem;
}

.black-popup .leaflet-popup-content-wrapper {
  color: white;
  background: rgba(0, 0, 0, 0.85);
  padding: 5px;
  min-width: 350px;
  border-radius: .55rem;
}

.black-popup-input {
  color: white;
  background: rgba(255, 255, 255, 0.25);
  font-size: 0.9em;
  border: none;
}

.leaflet-tooltip .leaflet-zoom-animated .leaflet-tooltip-right {
  z-index: 650;
  position: absolute;
  padding: 6px;
  color: white;
  background: rgba(0, 0, 0, 0.85);
  border: 1px solid #fff;
  border-radius: 30px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
