@import './../../../base.scss';

.container-checkbox {
  height: 24px;

  .checkbox-styles {
    .react-toggle-track {
      width: 48px;
      background-color: $checkboxBack;
    }

    .react-toggle-thumb {
      width: 20px;
      height: 20px;
      top: 2px;
      left: 2px;
      border: 0px;
      @include box-shadow(none);
      background-color: $checkboxItem;
    }

    &[class*='checked'] {
      .react-toggle-track {
        background-color: $checkboxActiveBack;
      }

      .react-toggle-thumb {
        left: 25px;
        @include box-shadow(none);
      }

      &:not(.react-toggle--disabled):hover {
        .react-toggle-track {
          background-color: $checkboxHoverActiveBack;
        }
      }
    }

    &:not(.react-toggle--disabled):hover {
      .react-toggle-track {
        background-color: $checkboxHoverBack;
      }
    }

    &:not(.react-toggle--disabled):active {
      .react-toggle-thumb {
        @include box-shadow(none);
      }
    }
  }
}