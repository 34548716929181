@import './../../../base.scss';

.buttonIconDefault {
  display: flex;
  padding: 0px;
  height: 40px;
  width: auto;
  min-width: 40px;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  border: solid 1px transparent;
  outline: none;
  cursor: pointer;
  @include border-radius(5px);
  @include font(1.125rem, 1, $buttonIconColor, null, null);
  @include box-shadow($shadow-level-3);
  @include transition(all $animateTransition);

  &:not(:disabled):hover {
    background-color: $color5-d;
    border-color: $buttonDefaultBorder;
  }

  &:disabled {
    background-color: $color5-d;
    border-color: $buttonDefaultBorder;
  }

  .wrapp {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1700px) {
  .buttonIconDefault {
    min-width: 30px;
    height: 30px;
  }
}