@import './../base.scss';

.secondSideBarInner{
  padding: 34px 0px 30px;
  border-bottom: solid 1px $horizontalLineColor;
  &:last-child{
    border-bottom: 0px;
    padding-bottom: 0px
  }
}
@media (max-width: 1700px) {
  .secondSideBarInner {
    padding: 20px 0px;
  }
}