@import "./../../../base.scss";

.employeeSetting {
  border-bottom: solid rgba(0, 0, 0, 0.05) 0.1rem;
  font-size: 0.7rem;
  padding: 5px;

  strong {
    display: block;
    padding-top: 10px;
    text-transform: uppercase;
  }

  input {
    margin-right: 10px;
    max-width: 180px;
  }

  .groupItem {
    display: flex;
    padding: 0px;
    margin: 0px;
  }

  span {
    margin-left: -3px;
  }

  .inputContainer {
    vertical-align: middle;

    .inputTime {
      width: 190px !important;
    }
  }
}

.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  width: 190px !important;
}

.employeeSettingContainer {
  padding: 5px 15px;
}
