@import './../../../base.scss';

.dropDownSelect {
  position: relative;

  .dropDownSelectButton {
    position: relative;
    display: block;
    background: none;
    @include border-radius(0px);
    @include box-shadow(0px);
    padding: 0px;
    padding-right: 12px;
    border: 0px;
    outline: none;
    cursor: pointer;
    @include font(0.775rem, 1.1875rem, $color1-b, null, null);

    .container_sortIcon {
      position: absolute;
      right: 0px;
      top: 8px;
      @include transform(rotate(0deg));
      @include font(0.25rem, 1, $color1-b, null, null);
    }
  }

  .dropDownSelectButton_open {
    @extend .dropDownSelectButton;

    .container_sortIcon {
      @include transform(rotate(180deg));
    }
  }

  .dropDownSelectItems {
    position: absolute;
    left: 0px;
    top: 100%;
    margin-top: 10px;
    z-index: 2;
    min-width: 185px;
    padding: 10px 0px 18px;
    background-color: $color-white;
    @include box-shadow($shadow-level-2);
    @include border-radius(5px);
  }

  .dropDownSelectItems_close {
    @extend .dropDownSelectItems;
    display: none;
  }

  .dropDownSelectItems_open {
    @extend .dropDownSelectItems;
    display: block;
  }
}