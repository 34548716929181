@import './../../base.scss';

.signInFooter {
  display: block;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 2;
  background-color: $color5-d
;
  text-align: center;
  padding: 18px 10px 10px;

  .copy {
    @include font(1rem, 1.5, $textColor, null, null);
  }

  .support {
    @include font(0.8571rem, 1.5rem, $textColor, null, null);
  }
}

@media (max-width: 1700px) {
  .signInFooter {
    min-width: 1366px;
  }
}