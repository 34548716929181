.employeeDeviceSettings_container {
    padding: 10px 0px;

    margin-bottom: 10px;
}

.employeeDeviceSettings_containerImei {
    padding: 5px 15px;
}

.selectImei {
    width: 150px;
    padding-left: 5px;
}

.employeeDeviceSettings_button button {
    max-height: 24px;
    margin-left: 20px;
}