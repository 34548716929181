@import "./../base.scss";
@import "./items/contols/buttonMediumPrimary.module.scss";
@import "./viewTableContentRow.scss";

.viewTableContentRow {
  cursor: pointer;

  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  @include box-shadow(inset 0px 0px 0px 0px $color1-a);
  $trans: box-shadow $animateTransition, background $animateTransition;
  @include transition($trans);
  border-top: solid 1px $tableBorder;
  justify-content: flex-start;

  &:nth-child(2n) {
    background-color: $tableStrechBack;
  }

  &:hover {
    background-color: $tableContentHover;
    @include box-shadow(inset 2px 0px 0px 0px $color1-a);

    .container_tableContentRowItem {
      & > *:not(button) {
        opacity: 1;
      }

      button {
        display: inline-block;
        @extend .buttonMediumPrimary;
      }
    }
  }

  .container_tableContentRowItem {
    text-align: left;
    padding-left: 5px;

    &:nth-child(1) {
      min-width: 120px;
      padding-left: 10px;
    }

    &:nth-child(2) {
      min-width: 110px;
    }

    &:nth-child(3) {
      min-width: 160px;
    }

    &:nth-child(4) {
      min-width: 60px;
      text-align: center;
      padding-left: 5px;
      padding-right: 5px;
    }

    &:nth-child(5) {
      width: 100%;
    }

    &:nth-child(6) {
      min-width: 350px;
      color: black;
    }

    & > *:not(button) {
      opacity: 1;
      @include transition(opacity $animateTransition);
    }

    button {
      display: inline-block;
    }
  }
}

.viewTableContentRow_active {
  @extend .viewTableContentRow;
  background-color: $tableContentHover;
  @include box-shadow(inset 0px 0px 0px 2px $color1-b);

  .container_tableContentRowItem {
    & > *:not(button) {
      opacity: 1;
    }

    button {
      display: inline-block;
      @extend .buttonMediumPrimary;
    }
  }

  &:hover {
    @include box-shadow(inset 0px 0px 0px 2px $color1-b);
  }

  &:nth-child(2n) {
    background-color: $tableContentHover;
  }
}
