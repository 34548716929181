@import "./../../../base.scss";

.objectivesContainer {
  box-sizing: border-box !important;
  height: 100%;
  background-color: white;
  border-radius: 5px;
  font-weight: 500;
  margin: 30px;
}

.kalendContainer {
  margin: 0px;
  height: 95%;
  margin-left: 5px;
  margin-bottom: 10px;
  box-shadow: -5px 5px 5px rgba(89, 90, 94, 0.3);
}

.tabObjectives {
  overflow: hidden;
}

.tabObjectives button {
  margin: 5px 0px 0px 0px;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  background: none;
}

.checkedView {
  font-weight: 600 !important;
  color: white;
  background-color: rgba(100, 148, 237) !important;
  margin: 5px 0px 0px 5px !important;
  border-radius: 5px 0px 0px 0px;
}

.tabObjectivesContent {
  margin: 0px;
  padding: 8px;
  height: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.serchAddresContainer {
  position: absolute;

  z-index: 1000;
  input {
    padding: 5px !important;
    border-radius: 5px;
    border-width: 1px;
    border-color: rgb(0 0 0 / 0%);
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    padding-left: 45px;
    padding-right: 30px;
    box-sizing: border-box;
    height: 28px;
  }
}

.buildingMapViewContainer {
  display: flex;
  width: 100%;
  font-size: 0.75rem;
  margin-bottom: 10px;
}

.objectiveCreatePanel {
  position: relative;
  display: flex;
  width: 100%;
  font-size: 0.75rem;

  img {
    border: none;
  }

  .leaflet-container {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 350px;
    margin-right: 5px;
  }

  label {
    margin: 5px 10px;
    font-weight: 600;
  }

  .contaunerCreateOptions {
    width: 750px;
    margin-top: 5px;
    padding: 5px;

    input {
      width: 50%;
      margin: 0px;
      margin-bottom: 15px;
    }

    label {
      margin: 0px;
      margin-bottom: 15px;
    }

    select {
      width: 50%;
      margin-bottom: 5px;
    }

    textarea {
      width: 85%;
      height: 100px;
      margin-bottom: 5px;
      vertical-align: top;
    }

    .custom-checkbox {
      display: inline-block;
      width: inherit;
    }
  }
}

.tableObjectives {
  max-height: 700px;
  padding: 5px;
}

.tableObjectivesRow {
  display: flex;
  padding: 0px;
  margin: 0px;
  line-height: 1.1rem;
  font-size: 0.85rem;
  margin-bottom: 10px;

  direction: initial;
  cursor: pointer;
  vertical-align: top;

  /*   div {
    flex-wrap: nowrap;
  } */

  div:nth-child(1) {
    width: 30%;
  }

  div:nth-child(2) {
    width: 40%;
  }

  div:nth-child(3) {
    width: 10%;
    text-align: center;
  }

  div:nth-child(4) {
    width: 10%;
    text-align: center;
  }

  div:nth-child(5) {
    width: 10%;
    text-align: center;
  }
}

.tableObjectivesRow:hover {
  box-shadow: 0px 0px 30px rgba(125, 161, 228, 0.3);

  div:nth-child(1) {
    font-weight: 900;
  }
}

.tableObjectivesRowHeader {
  padding: 0px;
  border-bottom: 0.2rem solid rgb(255, 255, 255);
  font-size: 0.73rem;
  font-weight: 900;
  text-transform: uppercase;
  background-color: rgba(180, 180, 180, 0.08);
  margin-top: 20px;

  div:nth-child(1) {
    width: 30%;
    display: inline-block;
  }

  div:nth-child(2) {
    width: 40%;
    display: inline-block;
  }

  div:nth-child(3) {
    width: 10%;
    text-align: center;
    display: inline-block;
  }

  div:nth-child(4) {
    text-align: center;
    width: 10%;
    display: inline-block;
  }

  div:nth-child(5) {
    text-align: center;
    width: 10%;
    display: inline-block;
  }
}

.shedulesContainer {
  width: 100%;
  padding-left: 10px;

  .listShedules {
    height: 200px;
    width: 100%;
    padding-top: 10px;

    .rowSheduleWeek {
      cursor: pointer;

      div:nth-child(1) {
        display: inline-block;
        width: 45%;
      }
      div:nth-child(2) {
        display: inline-block;
      }
    }
  }
}

.schedulePanel {
  width: 100%;
  cursor: pointer;

  .containerDayButton {
    border: 4px solid rgb(129 134 152);
    font-weight: 600;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    display: inline-block;
    padding: 0px;
    padding-top: 2px;
    margin: 3px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }

  .chekced {
    display: inline-block;
    border: 4px solid rgb(58, 100, 253);
  }
}

.schedulePanelCreated {
  width: 100%;
  border: 1px solid rgb(129 134 152);
  padding: 10px;

  .containerDayButton {
    border: 4px solid rgb(129 134 152);
    font-weight: 600;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    display: inline-block;
    padding: 0px;
    padding-top: 2px;
    margin: 3px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }

  .chekced {
    display: inline-block;
    border: 4px solid rgb(58, 100, 253);
  }
}

.schedulePanelView:hover {
  box-shadow: 0px 0px 30px rgba(125, 161, 228, 0.3);
}

.select_schedulePanelView {
  font-size: 0.55rem;
  font-weight: 900;
  padding: 0px 5px;
  cursor: pointer;
  width: 100%;
  border: 1px solid rgb(58, 100, 253);

  .containerDayButton {
    border: 3px solid rgb(129 134 152);
    border-radius: 50px;
    width: 24px;
    height: 24px;
    display: inline-block;
    padding: 0px;
    padding-top: 2px;
    margin: 3px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }

  .containetTimeInterval {
    display: inline-block;
    margin-left: 10px;
    font-size: 1rem;
    vertical-align: middle;
  }

  .containerDateInterval {
    display: inline-block;
    font-size: 1rem;
    vertical-align: middle;
  }

  .chekced {
    display: inline-block;
    border: 3px solid rgb(58, 100, 253);
  }
}

.schedulePanelView {
  display: flex;
  margin-bottom: 2px;
  font-size: 0.55rem;
  padding: 0px 5px;
  border-radius: 8px;
  cursor: pointer;

  .containerDayButton {
    border: 3px solid rgb(129 134 152);
    border-radius: 50px;
    width: 24px;
    height: 24px;
    display: inline-block;
    padding: 0px;
    padding-top: 2px;
    margin: 3px;
    text-align: center;
    vertical-align: middle;
  }

  .containetTimeInterval {
    display: inline-block;
    margin-left: 10px;
    font-size: 1rem;
    vertical-align: middle;
  }

  .containerDateInterval {
    display: inline-block;
    font-size: 1rem;
    vertical-align: middle;
    font-weight: 600;
  }

  .chekced {
    display: inline-block;
    border: 3px solid rgb(58, 100, 253);
  }
}

.employeeReproccesContainer {
  display: flex;
  align-items: flex-end;
  padding: 5px 15px 15px;
  margin: 0px;
}

.sheduleDateTimeInput {
  display: inline-block;
  width: 125px;
  padding: 5px;
  margin-right: 20px;
}

.scheduleRadioButtun {
  display: inline-block;
}

.tableObjectivesRowConcat {
  margin-left: 5px;

  .tableObjectivesRow {
    direction: initial;
    display: flex;
    border-bottom: 0.2rem solid rgb(255, 255, 255);
    font-weight: 900;
    background: white;

    span:nth-child(1) {
      width: 100%;
      font-size: 1em;
      text-transform: uppercase;
    }

    span:nth-child(2) {
      display: none;
    }

    span:nth-child(3) {
      display: none;
    }

    span:nth-child(4) {
      display: none;
    }
  }
}

.tableObjectivesOpenPanel {
  display: flex;
  margin-bottom: 10px;
  background: linear-gradient(to right, white, rgba(77, 161, 255, 0.1));
}

.tableObjectivesRowConcat_close {
  cursor: pointer;

  margin-left: auto;
}

.tableObjectivesRowConcat_close:hover {
  color: red;
}

.objectiveButtonWrapper {
  padding: 0px;
  margin: 0px;

  button {
    height: 30px;
    padding: 0px 20px;
    font-size: small;
  }
}

.rowButtonWrapper {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.scheduleEmployeesCountContainer {
  margin-right: auto;
  font-size: 1rem;
  margin-right: 15px;

  span {
    white-space: pre;
  }
}

.objectiveRemoveButtonWrapper {
  margin-left: auto;
  padding-top: 5px;

  button {
    height: 30px;
    padding: 5px 20px;
    font-size: small;
    background-color: rgb(255, 0, 0);
  }
}

.objectiveRemoveButtonWrapper:hover {
  color: red;
  button {
    background-color: rgb(190, 0, 0) !important;
  }
}
