@import "./../../../base.scss";

.employeeShortInformation {
  .container_moreEmployeeShortInfo {
    margin-top: 25px;
  }
}

.sharedLinkButton {
  position: absolute;

  margin-top: -35px;
  margin-left: 850px;
  width: 910px;

  svg:hover {
    color: red;
  }
}
