@import './../../../base.scss';

.employeesPanel{
  .container_sortFilter{
    display: flex;
    align-items: center;
    margin-top: 20px;
    
    .container_filter{
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      & > * + *{
        margin-left: 5px;
      }
    }
  }
}