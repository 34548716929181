@import "./../../../base.scss";

.usersList {
  position: relative;
  // padding-right: 27px;

  .container_usersListItem {
    cursor: pointer;
    font-size: 0.7rem;
    @include box-shadow(inset 0px 0px 0px 1px $employeeItemBorderColor);
    @include border-radius(5px);
    position: relative;
    overflow: hidden;
    background-color: $color-white;
    @include transition(box-shadow 0.2s);
    /* 
    &:hover {
      $shadow: inset 0px 0px 0px 1px $employeeItemBorderColor,
        $shadow-btn-sidebar-work;
      @include box-shadow($shadow);
    } */

    & + .container_usersListItem {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
