@import './../../../base.scss';

.container_formItemsInline {
  display: flex;
 // align-items: center;

  .container_formItemInline {
    &+.container_formItemInline {
      margin-left: 10px;
    }
  }

  &+.container_formItemsInline {
    margin-top: 20px;
  }
}