@import './../../../base.scss';

.modalWrapper {
  width: 1200px;
  overflow-x: hidden;
  
  .headBlock {
    padding: 32px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $modalBorders;

    .title {
      display: block;
      @include font(20px, 28px, $modalTitle, bold, null);
    }

    .closeBtn {
      display: block;
      font-size: 12px;
      color: $closeModalButton;
      cursor: pointer;
      opacity: 1;
      @include transition(opacity 0.3s);

      &:hover {
        opacity: 0.7;
      }
    }
  }
}