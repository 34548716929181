@import "./../base.scss";

.tabsNavbar {
  display: flex;
  align-items: left;

  .tabsNavbarItem {
    opacity: 0.4;
    @include font(1rem, 1.35rem, $navbarTitleItem, bold, null);
    @include transition(opacity $animateTransition);
    cursor: pointer;

    @media (max-width: 1700px) {
      @include font(1rem, 1.35rem, $navbarTitleItem, bold, null);
    }

    & + .tabsNavbarItem,
    & + .tabsNavbarItem_activeTab {
      margin-left: 30px;
    }
  }

  .tabsNavbarItem_activeTab {
    @extend .tabsNavbarItem;
    opacity: 1;
    cursor: default;
  }
}
