@import './../../../base.scss';

.sortEmployees {
  display: flex;
  position: relative;
  align-items: center;

  .container_direction {
    margin-right: 3px;
    @include font(0.375rem, 1, $buttonSearchEmployees, null, null);

    .container_direction_asc,
    .container_direction_desc {
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      &:hover {
        color: $color1-a;
      }
    }

    .container_direction_asc {
      align-items: flex-end;
      @include transition(color $animateTransition);
    }

    .container_direction_desc {
      align-items: flex-start;
      margin-top: 2px;
      @include transition(color $animateTransition);
    }

    .container_direction_asc_active,
    .container_direction_desc_active {
      color: $color1-b;
      cursor: default;

      &:hover {
        color: inherit;
      }
    }

    .container_direction_asc_active {
      @extend .container_direction_asc;
    }

    .container_direction_desc_active {
      @extend .container_direction_desc;
    }
  }
}