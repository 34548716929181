@import './../base.scss';

.container_sideContentBlock {
  background-color: $color-white;
  margin: 3px 30px 0px;
  @include box-shadow($shadow-level-3);
  @include border-radius(0px);
  width: calc(100% - 40px);
  min-height: 470px;

  &+.container_sideContentBlock {
    margin-top: 10px;
  }
}

.container_sideContentBlockBottom {
  background-color: $color-white;
  margin: 0px 10px 10px;
  @include box-shadow($shadow-level-3);
  @include border-radius(5px);
  width: calc(100% - 40px);
  min-height: 150px;

  &+.container_sideContentBlock {
    margin-top: 10px;
  }
}


@media (max-width: 1700px) {
  .container_sideContentBlock {
    margin: 0px 10px 10px;
    width: calc(100% - 10px);

    &+.container_sideContentBlock {
      margin-top: 10px;
    }
  }
}