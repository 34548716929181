@import './../../../base.scss';


.formItem {
  &+.formItem {
    margin-top: 35px;
  }

  .formItemTitle {
    display: block;
    @include font(0.75rem, 1.6875rem, $formInputTitle, null, null)
  }

  .formItemWrapper {
    color: $formInputDefaultContent;
  }
}