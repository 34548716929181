@import './../base.scss';

.Collapsible__trigger {
  .collapsibleHeader {
    cursor: pointer;
    display: flex;
    align-items: center;
    @include font(1rem, 1.5rem, $colllapsibleHeader, 600, null);

    .icon {
      margin-left: auto;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include font(0.875rem, 0.5rem, $buttonIconColor, null, null);
      @include transition($animateTransition);
    }
  }

  &.is-open {
    .collapsibleHeader {
      .icon {
        @include transform(rotate(90deg));
      }
    }
  }

  &.is-closed {
    .collapsibleHeader {
      .icon {
        @include transform(rotate(-90deg));
      }
    }
  }
}