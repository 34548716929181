@import "./../../../base.scss";

.intellegenceTimeRangeTable_scrolled {
  * {
    @include transition(all 0s !important);
  }
}

.container_preloader {
  display: flex;
  justify-content: center;
}