@import './../../../base.scss';

.phoneLink {
  cursor: pointer;
  @include transition(color $animateTransition);
  @include font(null, 1.5, $color1-b, normal, null);

  &:not(:disabled):hover {
    color: $color1-a;
  }

  &:disabled {
    opacity: 0.6;
  }
}