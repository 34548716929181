@import "./../../../base.scss";

.employeesListSelectedItem {
  .container_employeesListItemWrapper {
    position: relative;
    overflow: hidden;
    @include box-shadow(inset 0px 1px 0px 0px $employeeItemBorderColor);
  }

  .mDEmployeeItems {
    padding: 2px 2px 2px 38px;

    .container_employeeInfoLink {
      display: flex;
      justify-content: flex-end;
    }

    .mDEmployeeItem {
      & + .mDEmployeeItem {
        margin-top: 1px;
      }

      .mDEmployeeItemTitle {
        display: block;
        @include font(0.73rem, 1.25rem, $employeeTitle, 600, null);
      }

      .mDEmployeeItemContent {
        display: block;
        margin-top: 1px;
        @include font(0.7rem, 0.75rem, $formInputContentTitle, 600, null);
      }
    }

    p {
      color: rgb(111, 111, 111) !important;
      font-size: .67rem!important;
      padding-bottom: 6px!important;
    }
  }
  .mDEmployeeItems_noData {
    @extend .mDEmployeeItems;
    @include box-shadow(inset 18px 0px 0px 0px $color5-b);
  }

  .mDEmployeeItems_inSleep {
    @extend .mDEmployeeItems;
    @include box-shadow(inset 18px 0px 0px 0px $color1-a);
  }

  .mDEmployeeItems_work {
    @extend .mDEmployeeItems;
    @include box-shadow(inset 18px 0px 0px 0px $color3-b);
  }

  .mDEmployeeItems_charging {
    @extend .mDEmployeeItems;
    @include box-shadow(inset 18px 0px 0px 0px $color4-b);
  }

  .mDEmployeeItems_offSleep {
    @extend .mDEmployeeItems;
    @include box-shadow(inset 18px 0px 0px 0px $color1-b);
  }

  .mDEmployeeItems_alarm {
    @extend .mDEmployeeItems;
    @include box-shadow(inset 18px 0px 0px 0px $color2-a);
  }

  .mDEmployeeItems_poweredFrom {
    @extend .mDEmployeeItems;
    @include box-shadow(inset 18px 0px 0px 0px $color4-a);
  }
}
