@import "./../base.scss";

.userState {
  display: flex;
  text-align: right;
  
  .userImage {
    width: 38px;
    height: 38px;
  }

  .userName {
    display: block;
    @include font(0.9375rem, 1.25rem, $color5-a, null, null);
  }

  .userPosition {
    display: block;
    @include font(0.75rem, 1.1875rem, $userStatePosition, null, null);
  }

  .container_userStateData {
    margin-left: 17px;
  }
}
