@import './../../base.scss';

.container_secodSideBarTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .container_secodSideBarTitleText {
    &+* {
      margin-left: auto;
    }
  }

  .container_closeSecodSideBar {
    margin-left: 15px;
  }
}