@import './../../../base.scss';

.buttonMediumDefault {
  position: relative;
  display: block;
  min-width: 52px;
  max-width: 305px;
  padding: 0px 5px;
  height: 21px;
  text-transform: uppercase;
  text-align: center;
  background-color: $buttonDefaultBack;
  border: solid 1px $buttonDefaultBorder;
  outline: none;
  $trans: background $animateTransition, border $animateTransition;
  @include transition($trans);
  @include box-shadow(none);
  @include border-radius(5px);
  @include font(0.625rem, 0.875rem, $buttonDefault, bold, 0.0357rem);
  cursor: pointer;

  &:not(:disabled):hover {
    background-color: $buttonDefaultBorder;
  }

  &:disabled {
    opacity: 0.5;
  }

  .wrapp {
    display: flex;
    align-items: center;
  }
}

.buttonMediumDefault_active {
  @extend .buttonMediumDefault;
  background-color: $buttonDefaultBorder;
  border-color: $buttonDefaultActiveBorder;
}

@media (max-width: 1700px) {
  .buttonMediumDefault {
    min-width: 26px;

    .wrapp {
      justify-content: center;
    }
  }
}