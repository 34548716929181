@import './../../../base.scss';

.container_modalRoute {
  padding: 30px;

  .container_routeMap {
    &+.container_routeSlider {
      margin-top: 30px;
    }
  }

  .container_routeSlider {
    &+.container_routeTable {
      margin-top: 30px;
    }
  }
}

.container_pointDate {
  text-align: right;

  .pointDate {
    font-size: 14px;
    line-height: 16px;
    color: $color1-b;
  }
}

.container_routeMap {
  position: relative;
  height: 200px;
  overflow: hidden;
}