@import "./../../../base.scss";

.employeesList {
  position: relative;

  .container_employeesListItem {
    @include box-shadow(inset 0px 0px 0px 1px $employeeItemBorderColor);
    @include border-radius(5px);
    position: relative;
    overflow: hidden;
    background-color: $color-white;
    margin-bottom: 2px;
    @include transition(box-shadow 0.2s);

    & + .container_employeesListItem {
      margin-top: 5px;
    }
  }

  .container_employeesListItem_noData {
    @extend .container_employeesListItem;
    background: linear-gradient(90deg, rgba(144, 146, 151, 0.2), white);

    &:hover {
      $shadow: inset 0px 0px 0px 1px $employeeItemBorderColor, $shadow-btn-sidebar-noData;
      @include box-shadow($shadow);
    }
  }

  .container_employeesListItem_inSleep {
    @extend .container_employeesListItem;
    background: linear-gradient(90deg, rgba(17, 112, 201, 0.2), white);

    &:hover {
      $shadow: inset 0px 0px 0px 1px $employeeItemBorderColor, $shadow-btn-sidebar-inSleep;
      @include box-shadow($shadow);
    }
  }

  .container_employeesListItem_work {
    @extend .container_employeesListItem;
    background: linear-gradient(90deg, rgba(84, 203, 100, 0.2), white);

    &:hover {
      $shadow: inset 0px 0px 0px 1px $employeeItemBorderColor, $shadow-btn-sidebar-work;
      @include box-shadow($shadow);
    }
  }

  .container_employeesListItem_charging {
    @extend .container_employeesListItem;
    background: linear-gradient(90deg, rgba(255, 224, 87, 0.2), white);

    &:hover {
      $shadow: inset 0px 0px 0px 1px $employeeItemBorderColor, $shadow-btn-sidebar-charging;
      @include box-shadow($shadow);
    }
  }

  .container_employeesListItem_poweredFrom {
    @extend .container_employeesListItem;
    background: linear-gradient(90deg, rgba(250, 157, 34, 0.2), white);

    &:hover {
      $shadow: inset 0px 0px 0px 1px $employeeItemBorderColor, $shadow-btn-sidebar-poweredFrom;
      @include box-shadow($shadow);
    }
  }

  .container_employeesListItem_offSleep {
    @extend .container_employeesListItem;
    background: linear-gradient(90deg, rgba(77, 161, 255, 0.2), white);

    &:hover {
      $shadow: inset 0px 0px 0px 1px $employeeItemBorderColor, $shadow-btn-sidebar-offSleep;
      @include box-shadow($shadow);
    }
  }

  .container_employeesListItem_alarm {
    @extend .container_employeesListItem;
    background: linear-gradient(90deg, rgba(233, 62, 62, 0.2), white);

    &:hover {
      $shadow: inset 0px 0px 0px 1px $employeeItemBorderColor, $shadow-btn-sidebar-alarm;
      @include box-shadow($shadow);
    }
  }

  .container_employeesListItem_not_multipleselected {
    @extend .container_employeesListItem;
    cursor: pointer;

    &:hover {
      $shadow: inset 0px 0px 0px 1px $employeeItemBorderColor, $shadow-btn-sidebar-not-multipleselected;
      @include box-shadow($shadow);
    }
  }

  .container_employeesListItem_multipleselected {
    @extend .container_employeesListItem;
    cursor: pointer;
    background: linear-gradient(90deg, rgba(134, 144, 233, 0.1), white);

    &:hover {
      $shadow: inset 0px 0px 0px 1px $employeeItemBorderColor, $shadow-btn-sidebar-multipleselected;
      @include box-shadow($shadow);
    }
  }
}
