@import './../../../base.scss';

.buttonPrimary {
  display: block;
  float:  right;
  max-width: 196px;
  padding: 0px 5px;
  height: 25px;
  text-transform: uppercase;
  text-align: center;
  background-color: $color1-b;
  border: solid 0px;
  outline: none;
  @include transition(all $animateTransition);
  @include box-shadow(none);
  @include border-radius(5px);
  @include font(0.5571rem, 1.1429, $color-white, bold, 0.0857rem);
  cursor: pointer;

  &:not(:disabled):hover {
    background-color: $color1-a;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
  .wrapp{
    display: flex;
    align-items: center;
  }
}