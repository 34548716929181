@import "./../base.scss";
@import "./items/contols/buttonMediumPrimary.module.scss";

.viewObjectivesTable {
  cursor: pointer;
  padding: 5px 10px;
  width: 140px;
  text-align: left;
}

.viewObjectivesTable:nth-of-type(5) {
  width: auto;
  max-width: 40%;
}

.viewObjectivesTable_heder {
  cursor: pointer;
  padding: 5px 10px;
  width: 140px;
  text-align: left;
  font-size: .75em;
  text-transform: uppercase;
}

.viewObjectivesTable_heder:nth-of-type(1) {
  width: 100px;
  margin-left: 30px;
}

.viewObjectivesTable_heder:nth-of-type(2) {
  text-align: center;
}

.viewObjectivesTable_heder:nth-of-type(5) {
  width: auto;
}
