@import "./../../../base.scss";

.intellegenceTimeRangeContent {
  .container_controls {
    margin-top: -85px;
    padding: 0px;
  }


}
