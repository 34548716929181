@import './../../base.scss';

.sideSecondBar {
  min-height: $minHeightSides;
  @include box-shadow($shadow-level-3);
  background-color: $backFilter;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 380px;
  @include transition(transform $animateTransition);
  padding: 0px 30px;

  .container_sideSecondContent {
    height: calc(100vh - 163px);

    @include customScrollOuter;
  }
}

.sideSecondBar_closed {
  @extend .sideSecondBar;
  @include transform(translateX(0));
}

.sideSecondBar_open {
  @extend .sideSecondBar;
  @include transform(translateX($sideWidth));
}

@media (max-width: 1700px) {
  .sideSecondBar {
    padding: 0px 20px;
  }
  
  .sideSecondBar_closed {
    @include transform(translateX(-60px));
  }

  .sideSecondBar_open {
    @include transform(translateX(320px));
  }
}