@import './../../../base.scss';

.buttonMediumPrimary {
  display: block;
  width: auto;
  min-width: 52px;
  padding: 0px 5px;
  height: 26px;
  text-transform: uppercase;
  text-align: center;
  background-color: $color1-b;
  border: solid 1px transparent;
  outline: none;
  @include transition(background $animateTransition);
  @include box-shadow(none);
  @include border-radius(5px);
  @include font(0.625rem, 0.875rem, $color-white, bold, 0.0357rem);
  cursor: pointer;

  &:not(:disabled):hover {
    background-color: $color1-a;
  }

  &:disabled {
    opacity: 0.5;
  }

  .wrapp {
    display: flex;
    align-items: center;
  }
}