@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?nkqh4b');
  src:  url('fonts/icomoon.eot?nkqh4b#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?nkqh4b') format('truetype'),
    url('fonts/icomoon.woff?nkqh4b') format('woff'),
    url('fonts/icomoon.svg?nkqh4b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-printer:before {
  content: "\e90a";
}
.icon-icon-check:before {
  content: "\e9b5";
  color: rgba(83, 81, 81, 0.1);
}
.icon-icon-check-activ:before {
  content: "\e9b7";
  font-size: x-large;
}
.icon-icon-check-active .path2:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-default-width:before {
  content: "\e9b4";
}
.icon-full-width:before {
  content: "\e9b3";
}
.icon-icon-2:before {
  content: "\e9ae";
}
.icon-filter:before {
  content: "\e908";
}
.icon-fav:before {
  content: "\e90a";
}
.icon-Group-9:before {
  content: "\e900";
}
.icon-icon-1:before {
  content: "\e90b";
}
.icon-Intersect-1:before {
  content: "\e90c";
}
.icon-Intersect-2:before {
  content: "\e90e";
}
.icon-Intersect-3:before {
  content: "\e910";
}
.icon-Intersect-4:before {
  content: "\e911";
}
.icon-Intersect-5:before {
  content: "\e99d";
}
.icon-Intersect-6:before {
  content: "\e9a0";
}
.icon-Intersect:before {
  content: "\e9a1";
}
.icon-racing:before {
  content: "\e9a2";
}
.icon-Union-Stroke:before {
  content: "\e9a3";
}
.icon-Union:before {
  content: "\e9a4";
}
.icon-Vector-11:before {
  content: "\e9a5";
}
.icon-Vector3:before {
  content: "\e9a6";
}
.icon-Vector2:before {
  content: "\e9a7";
}
.icon-Vector-1:before {
  content: "\e9a8";
}
.icon-Path-178-Stroke:before {
  content: "\e9a9";
}
.icon-Subtract:before {
  content: "\e9aa";
}
.icon-direction-desc:before {
  content: "\e9ab";
}
.icon-direction-asc:before {
  content: "\e9ac";
}
.icon-Path-178-Stroke1:before {
  content: "\e9ad";
}
.icon-arr:before {
  content: "\e9af";
}
.icon-Group:before {
  content: "\e9b0";
}
.icon-Subtract1:before {
  content: "\e9b1";
}
.icon-arr1:before {
  content: "\e9b2";
}
.icon-backward:before {
  content: "\e901";
}
.icon-forward:before {
  content: "\e902";
}
.icon-pause:before {
  content: "\e903";
}
.icon-play:before {
  content: "\e904";
}
.icon-stop:before {
  content: "\e905";
}
.icon-envelope:before {
  content: "\e906";
}
.icon-export:before {
  content: "\e907";
}
.icon-file:before {
  content: "\e909";
}
.icon-car:before {
  content: "\e90d";
}
.icon-fav1:before {
  content: "\e90f";
}
.icon-lock-open:before {
  content: "\1f513";
}
.icon-cancel:before {
  content: "\1f5d9";
}
.icon-lock:before {
  font-family: 'emoji';
  content: "\1f50f";
}
.icon-user:before {
  content: "\e912";
}
.icon-warning:before {
  content: "\e913";
}
.icon-x:before {
  content: "\e914";
}
.icon-z .path1:before {
  content: "\e915";
  color: rgb(226, 229, 231);
}
.icon-z .path2:before {
  content: "\e916";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-z .path3:before {
  content: "\e917";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-z .path4:before {
  content: "\e918";
  margin-left: -0.884765625em;
  color: rgb(6, 80, 127);
}
.icon-z .path5:before {
  content: "\e919";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-z .path6:before {
  content: "\e91a";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-z .path7:before {
  content: "\e91b";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-csv .path1:before {
  content: "\e91c";
  color: rgb(226, 229, 231);
}
.icon-csv .path2:before {
  content: "\e91d";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-csv .path3:before {
  content: "\e91e";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-csv .path4:before {
  content: "\e91f";
  margin-left: -0.884765625em;
  color: rgb(84, 203, 100);
}
.icon-csv .path5:before {
  content: "\e920";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-csv .path6:before {
  content: "\e921";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-csv .path7:before {
  content: "\e922";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-csv .path8:before {
  content: "\e923";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-doc .path1:before {
  content: "\e924";
  color: rgb(226, 229, 231);
}
.icon-doc .path2:before {
  content: "\e925";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-doc .path3:before {
  content: "\e926";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-doc .path4:before {
  content: "\e927";
  margin-left: -0.884765625em;
  color: rgb(77, 161, 255);
}
.icon-doc .path5:before {
  content: "\e928";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-doc .path6:before {
  content: "\e929";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-doc .path7:before {
  content: "\e92a";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-doc .path8:before {
  content: "\e92b";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-docx .path1:before {
  content: "\e92c";
  color: rgb(226, 229, 231);
}
.icon-docx .path2:before {
  content: "\e92d";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-docx .path3:before {
  content: "\e92e";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-docx .path4:before {
  content: "\e92f";
  margin-left: -0.884765625em;
  color: rgb(77, 161, 255);
}
.icon-docx .path5:before {
  content: "\e930";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-docx .path6:before {
  content: "\e931";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-docx .path7:before {
  content: "\e932";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-docx .path8:before {
  content: "\e933";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-docx .path9:before {
  content: "\e934";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-jpeg .path1:before {
  content: "\e935";
  color: rgb(226, 229, 231);
}
.icon-jpeg .path2:before {
  content: "\e936";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-jpeg .path3:before {
  content: "\e937";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-jpeg .path4:before {
  content: "\e938";
  margin-left: -0.884765625em;
  color: rgb(255, 98, 108);
}
.icon-jpeg .path5:before {
  content: "\e939";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-jpeg .path6:before {
  content: "\e93a";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-jpeg .path7:before {
  content: "\e93b";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-jpeg .path8:before {
  content: "\e93c";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-jpeg .path9:before {
  content: "\e93d";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-jpg .path1:before {
  content: "\e93e";
  color: rgb(226, 229, 231);
}
.icon-jpg .path2:before {
  content: "\e93f";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-jpg .path3:before {
  content: "\e940";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-jpg .path4:before {
  content: "\e941";
  margin-left: -0.884765625em;
  color: rgb(255, 98, 108);
}
.icon-jpg .path5:before {
  content: "\e942";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path6:before {
  content: "\e943";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path7:before {
  content: "\e944";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path8:before {
  content: "\e945";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-others .path1:before {
  content: "\e946";
  color: rgb(226, 229, 231);
}
.icon-others .path2:before {
  content: "\e947";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-others .path3:before {
  content: "\e948";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-others .path4:before {
  content: "\e949";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-others .path5:before {
  content: "\e94a";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-pdf .path1:before {
  content: "\e94b";
  color: rgb(226, 229, 231);
}
.icon-pdf .path2:before {
  content: "\e94c";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-pdf .path3:before {
  content: "\e94d";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-pdf .path4:before {
  content: "\e94e";
  margin-left: -0.884765625em;
  color: rgb(255, 98, 108);
}
.icon-pdf .path5:before {
  content: "\e94f";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path6:before {
  content: "\e950";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path7:before {
  content: "\e951";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path8:before {
  content: "\e952";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-png .path1:before {
  content: "\e953";
  color: rgb(226, 229, 231);
}
.icon-png .path2:before {
  content: "\e954";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-png .path3:before {
  content: "\e955";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-png .path4:before {
  content: "\e956";
  margin-left: -0.884765625em;
  color: rgb(255, 98, 108);
}
.icon-png .path5:before {
  content: "\e957";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-png .path6:before {
  content: "\e958";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-png .path7:before {
  content: "\e959";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-png .path8:before {
  content: "\e95a";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-pps .path1:before {
  content: "\e95b";
  color: rgb(226, 229, 231);
}
.icon-pps .path2:before {
  content: "\e95c";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-pps .path3:before {
  content: "\e95d";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-pps .path4:before {
  content: "\e95e";
  margin-left: -0.884765625em;
  color: rgb(247, 191, 71);
}
.icon-pps .path5:before {
  content: "\e95f";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-pps .path6:before {
  content: "\e960";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-pps .path7:before {
  content: "\e961";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-pps .path8:before {
  content: "\e962";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-ppt .path1:before {
  content: "\e963";
  color: rgb(226, 229, 231);
}
.icon-ppt .path2:before {
  content: "\e964";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-ppt .path3:before {
  content: "\e965";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-ppt .path4:before {
  content: "\e966";
  margin-left: -0.884765625em;
  color: rgb(247, 191, 71);
}
.icon-ppt .path5:before {
  content: "\e967";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-ppt .path6:before {
  content: "\e968";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-ppt .path7:before {
  content: "\e969";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-ppt .path8:before {
  content: "\e96a";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-pptx .path1:before {
  content: "\e96b";
  color: rgb(226, 229, 231);
}
.icon-pptx .path2:before {
  content: "\e96c";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-pptx .path3:before {
  content: "\e96d";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-pptx .path4:before {
  content: "\e96e";
  margin-left: -0.884765625em;
  color: rgb(247, 191, 71);
}
.icon-pptx .path5:before {
  content: "\e96f";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-pptx .path6:before {
  content: "\e970";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-pptx .path7:before {
  content: "\e971";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-pptx .path8:before {
  content: "\e972";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-pptx .path9:before {
  content: "\e973";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-rtf .path1:before {
  content: "\e974";
  color: rgb(226, 229, 231);
}
.icon-rtf .path2:before {
  content: "\e975";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-rtf .path3:before {
  content: "\e976";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-rtf .path4:before {
  content: "\e977";
  margin-left: -0.884765625em;
  color: rgb(77, 161, 255);
}
.icon-rtf .path5:before {
  content: "\e978";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-rtf .path6:before {
  content: "\e979";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-rtf .path7:before {
  content: "\e97a";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-rtf .path8:before {
  content: "\e97b";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-txt .path1:before {
  content: "\e97c";
  color: rgb(226, 229, 231);
}
.icon-txt .path2:before {
  content: "\e97d";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-txt .path3:before {
  content: "\e97e";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-txt .path4:before {
  content: "\e97f";
  margin-left: -0.884765625em;
  color: rgb(77, 161, 255);
}
.icon-txt .path5:before {
  content: "\e980";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-txt .path6:before {
  content: "\e981";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-txt .path7:before {
  content: "\e982";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-txt .path8:before {
  content: "\e983";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-xls .path1:before {
  content: "\e984";
  color: rgb(226, 229, 231);
}
.icon-xls .path2:before {
  content: "\e985";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-xls .path3:before {
  content: "\e986";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-xls .path4:before {
  content: "\e987";
  margin-left: -0.884765625em;
  color: rgb(84, 203, 100);
}
.icon-xls .path5:before {
  content: "\e988";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-xls .path6:before {
  content: "\e989";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-xls .path7:before {
  content: "\e98a";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-xls .path8:before {
  content: "\e98b";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-xlsx .path1:before {
  content: "\e98c";
  color: rgb(226, 229, 231);
}
.icon-xlsx .path2:before {
  content: "\e98d";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-xlsx .path3:before {
  content: "\e98e";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-xlsx .path4:before {
  content: "\e98f";
  margin-left: -0.884765625em;
  color: rgb(84, 203, 100);
}
.icon-xlsx .path5:before {
  content: "\e990";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-xlsx .path6:before {
  content: "\e991";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-xlsx .path7:before {
  content: "\e992";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-xlsx .path8:before {
  content: "\e993";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-xlsx .path9:before {
  content: "\e994";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-zip .path1:before {
  content: "\e995";
  color: rgb(226, 229, 231);
}
.icon-zip .path2:before {
  content: "\e996";
  margin-left: -0.884765625em;
  color: rgb(176, 183, 189);
}
.icon-zip .path3:before {
  content: "\e997";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-zip .path4:before {
  content: "\e998";
  margin-left: -0.884765625em;
  color: rgb(6, 80, 127);
}
.icon-zip .path5:before {
  content: "\e999";
  margin-left: -0.884765625em;
  color: rgb(202, 209, 216);
}
.icon-zip .path6:before {
  content: "\e99a";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-zip .path7:before {
  content: "\e99b";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-zip .path8:before {
  content: "\e99c";
  margin-left: -0.884765625em;
  color: rgb(255, 255, 255);
}
.icon-search:before {
  content: "\e99e";
}
.icon-Shape:before {
  content: "\e99f";
}
