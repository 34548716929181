@import './../../../base.scss';

.buttonCounter{
  display: block;
  margin-left: 5px;
  height: 12px;
  min-width: 12px;
  padding: 0px 3px;
  text-align: center;
  background-color: $color2-b;
  @include border-radius(12px);
  @include font(0.5rem, 11px, $color-white, bold, null);
}