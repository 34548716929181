@import "./../../../base.scss";

.employeeStatusTitle {
  display: flex;
  align-items: center;

  &:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    @include border-radius(50%);
    border: solid 5px transparent;
    margin-right: 5px;
  }
}

.employeeStatusTitle_noData {
  @extend .employeeStatusTitle;

  &:before {
    border-color: $color5-a;
  }
}

.employeeStatusTitle_inSleep {
  @extend .employeeStatusTitle;

  &:before {
    border-color: $color1-a;
  }
}

.employeeStatusTitle_work {
  @extend .employeeStatusTitle;

  &:before {
    border-color: $color3-b;
  }
}

.employeeStatusTitle_charging {
  @extend .employeeStatusTitle;

  &:before {
    border-color: $color4-b;
  }
}

.employeeStatusTitle_poweredFrom {
  @extend .employeeStatusTitle;

  &:before {
    border-color: $color4-a;
  }
}

.employeeStatusTitle_offSleep {
  @extend .employeeStatusTitle;

  &:before {
    border-color: $color1-b;
  }
}

.employeeStatusTitle_alarm {
  @extend .employeeStatusTitle;

  &:before {
    border-color: $color2-a;
  }
}
