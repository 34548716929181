@import './../base.scss';

.titleClosedBarWrapper {
  text-align: center;
  position: absolute;
  width: $minHeightSides;
  top: calc(50% - 0.875rem);
  left: calc((-100vh + 68px) / 2);
  margin-left: 1.75rem;
  @include transform(rotate(-90deg));
  @include font(1.25rem, 1.75rem, $titleBar, bold, null);
}