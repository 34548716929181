@import "./../../../base.scss";

.dropDown {
  position: relative;
  display: flex;
  justify-content: flex-end;

  .dropDownButton {
    position: relative;
    display: block;
    background: none;
    @include border-radius(0px);
    @include box-shadow(0px);
    padding: 0px;
    padding-right: 15px;
    border: 0px;
    outline: none;
    cursor: pointer;

    .icon {
      position: absolute;
      right: 0px;
      top: 8px;
      @include transform(rotate(0deg));
      @include font(0.375rem, 1, $dropDownArrow, null, null);
    }
  }

  .dropDownButton_open {
    @extend .dropDownButton;

    .icon {
      @include transform(rotate(180deg));
    }
  }

  .dropDownButton_close {
    @extend .dropDownButton;
  }

  .dropDownItems {
    position: absolute;
    right: 0px;
    top: 100%;
    margin-top: 10px;
    z-index: 2;
    min-width: 185px;
    padding: 10px 0px 18px;
    background-color: $color-white;
    @include box-shadow($shadow-level-2);
    @include border-radius(5px);
  }

  .dropDownItems_close {
    @extend .dropDownItems;
    display: none;
  }

  .dropDownItems_open {
    @extend .dropDownItems;
    display: block;
  }
}
