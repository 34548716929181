@import './../../../base.scss';


.formHorisontItem {
  display: flex;
  align-items: center;
  justify-content: center;

  &+.formHorisontItem {
    margin-top: 30px;
  }

  .formHorisontItemTitle {
    display: block;
    @include font(0.775rem, 1.6875rem, $formInputTitle, null, null)
  }

  .formHorisontItemWrapper {
    margin-left: auto;
  }
}