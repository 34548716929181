@import './../../../base.scss';

.intellegenceEmployeesModal {
  padding: 30px;

  .container_settings {
    padding-bottom: 35px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid $intellegenceItemBorder;
  }

  .container_dates {
    .container_dateItem {
      width: 140px;
    }
  }

  .buttonsBlock {
    padding: 5px;
    border: 1px solid $intellegenceItemBorder;
    @include border-radius(5px);

    .container_exportItem {
      display: inline-block;

      &+.container_exportItem {
        margin-left: 5px;
      }
    }
  }

  .conatiner_info {
    padding-top: 40px;

    .title {
      display: block;
      @include font(16px, 24px, $modalTitle, null, null);

      &+.container_deviceItems {
        margin-top: 15px;
      }
    }

    .container_deviceItems {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: minmax(60px, auto);
      grid-gap: 10px;
    }

    .container_preloader {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}