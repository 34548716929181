@import "./../base.scss";

.viewTable {
  .container_table {
    display: block;
    width: 100%;
    border: solid 1px $tableBorder;
    @include border-radius(5px);
  }

  .printerButton {
    background-image: url("/img/printer.png");
    border: none;
    right: 53;
    width: 24px;
    height: 24px;
    background-size: 25px;
    position: absolute;
    margin: 2.8px;
  }
}
