@import './../../../base.scss';

.deviceItem {
  visibility: hidden;
  height: 100%;
  border: solid 1px $deviceItemBorder;
  @include border-radius(5px);
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 6px 6px 2px;
  background-color: $deviceItemBack;

  .deviceTitle {
    display: block;    
    font-style: initial;
    @include font(0.8rem, 1.1875rem, $deviceItemTitle, bold, null);
  }

  .deviceValue {
    display: block;
    @include font(0.85rem, 1.5rem, $deviceItemContent, null, null);
  }

  .container_iconDevice {
    width: 62px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: -1;
    right: 0px;
    bottom: 0px;
    opacity: 0.5;
    @include font(62px, 1, $deviceItemIcon, null, null)
  }
}