@import "./../../../base.scss";

.employeesListItemWrapper {
  .linkWrapper {
    &:hover {
      cursor: pointer;
    }
  }

  .container_bookmark {
    position: absolute;
    top: 6px;
    right: 5px;
    @include font(1rem, 0.7rem, $notIsBookmarkOnEmployees, null, null);
    @include transition(color 0.3s);
    font-size: 0.7rem !important;

    &:hover {
      color: $color1-b;
    }
  }

  .container_mSelect {
    position: absolute;
    top: 4px;
    right: 5px;
    @include font(1rem, 1rem, $notIsBookmarkOnEmployees, null, null);
    @include transition(color 0.3s);
    color: rgb(173, 173, 173) ;
   
    &:hover {
      color: $color1-b;
    }
  }

  .employeesListItem {
    padding: 5px 0px 5px 30px;

    .container_employeeNumber {
      position: absolute;
      width: 18px;
      height: 100%;
      left: 0px;
      top: 0px;

      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -9px;
        margin-left: -40px;
        width: 80px;
        height: 18px;
        text-align: center;
        @include font(0.75rem, 18px, $color-white, 500, null);
        @include transform(rotate(-90deg));
      }
    }

    .container_employeeNumber_noData {
      @extend .container_employeeNumber;
      background-color: $color5-b;
    }

    .container_employeeNumber_inSleep {
      @extend .container_employeeNumber;
      background-color: $color1-a;
    }

    .container_employeeNumber_work {
      @extend .container_employeeNumber;
      background-color: $color3-b;
    }

    .container_employeeNumber_charging {
      @extend .container_employeeNumber;
      background-color: $color4-b;
    }

    .container_employeeNumber_offSleep {
      @extend .container_employeeNumber;
      background-color: $color1-b;
    }

    .container_employeeNumber_alarm {
      @extend .container_employeeNumber;
      background-color: $color2-a;
    }

    .container_employeeNumber_poweredFrom {
      @extend .container_employeeNumber;
      background-color: $color4-a;
    }

    .container_employeeContent {
      .container_name {
        @include font(0.8rem, 0.75rem, $employeeTitle, 600, null);
      }
    }
  }
}

.employeesListItem_isBookmark {
  @extend .employeesListItem;

  .container_bookmark {
    color: $color4-b;

    &:hover {
      color: $color1-b;
    }
  }
}

.employeesListItem_is_mSelect {
  @extend .employeesListItem;

  .container_mSelect {
    color: $color1-b1;

    &:hover {
      color: $color1-b;
    }
  }
}
