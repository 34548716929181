.tabButtonContainer {
  display: flex;
  margin: 0px;
  padding: 0px;

  button {
    cursor: pointer;
    float: left;
    border: none;
    outline: none;
    padding: 8px 16px;
    transition: 0.6s;
    font-size: 0.75rem;
    font-weight: 600;
    background: none;
  }
}
