@import './../../../base.scss';

.inputStyle {
  // overflow: hidden;

  input {
    padding: 0px;
    border: 0px;
    outline: none;
    padding: 8px 4px;
    height: 26px;
    display: block;
    width: 100%;
    background-color: $color-white;
    border: solid 1px $inputBorderColor;
    @include transition(all $animateTransition);
    @include border-radius(5px);
    @include font(1rem, 1.8285, $inputTextColor, normal, null);
    @include box-sizing(border-box);

    &:focus {
      border-color: $color1-b;
      color: $inputValueTextColor;
    }
    & + .container_validateMessage{
      position: absolute;
      line-height: 1;
    }
  }
}

.inputStyle_value {
  @extend .inputStyle;
  input {
    color: $inputValueTextColor;
  }
}

.inputStyle_error {
  @extend .inputStyle;
  input {
    border-color: $color2-b;
    color: $inputValueTextColor;
  }
}

.inputStyle_disabled {
  @extend .inputStyle;
  background-color: $inputDisabledBackColor;
}