$containerLG: 1495px;
$containerSD: 1200px;
$containerMD: 1030px;
$containerSM: 780px;
$containerXS: 100%;
$containerXSS: 100%;

$containerMediumLG: 970px;
$containerMediumSD: 970px;
$containerMediumMD: 100%;
$containerMediumSM: 100%;
$containerMediumXS: 100%;
$containerMediumXSS: 100%;

$containerSmallLG: 830px;
$containerSmallSD: 830px;
$containerSmallMD: 830px;
$containerSmallSM: 730px;
$containerSmallXS: 100%;
$containerSmallXSS: 100%;

$containerXSmallLG: 800px;
$containerXSmallSD: 800px;
$containerXSmallMD: 800px;
$containerXSmallSM: 100%;
$containerXSmallXS: 100%;
$containerXSmallXSS: 100%;

$indent1: 15px;
$indent2: 20px;
$indent3: 30px;
$indent4: 40px;
$indent5: 60px;
$indent6: 10px;
$indentO1: -$indent1;
$indentO2: -$indent2;
$indentO3: -$indent3;
$indentO4: -$indent4;
$indentO5: -$indent5;
$indentO6: -$indent6;

$containerPaddingLG: 0px $indent1;
$containerPaddingSD: 0px $indent1;
$containerPaddingMD: 0px $indent1;
$containerPaddingSM: 0px $indent1;
$containerPaddingXS: 0px $indent2;
$containerPaddingXSS: 0px $indent2;

$rowMarginLG: 0px $indentO3;
$rowMarginSD: 0px $indentO3;
$rowMarginMD: 0px $indentO3;
$rowMarginSM: 0px $indentO3;
$rowMarginXS: 0px $indentO2;
$rowMarginXSS: 0px $indentO2;

$maxMediaSD: 1439px;
$maxMediaMD: 1229px;
$maxMediaSM: 1047px;
$maxMediaXS: 797px;
$maxMediaXSS: 479px;

$minMediaSD: 1440px;
$minMediaMD: 1230px;
$minMediaSM: 992px;
$minMediaXS: 768px;
$minMediaXSS: 480px;

$col1: calc(100% * 1 / 12);
$col2: calc(100% * 2 / 12);
$col2_4: calc(100% / 5);
$col3: calc(100% * 3 / 12);
$col4: calc(100% * 4 / 12);
$col5: calc(100% * 5 / 12);
$col6: calc(100% * 6 / 12);
$col7: calc(100% * 7 / 12);
$col8: calc(100% * 8 / 12);
$col9: calc(100% * 9 / 12);
$col10: calc(100% * 10 / 12);
$col11: calc(100% * 11 / 12);
$col12: calc(100% * 12 / 12);

$baseSize: 11px !important;

$font1: "Open Sans";
$font2: "icomoon";

$color-white: #ffffff;

$color1-a: #3b6e9e;
$color1-b: #4da1ff;
$color1-b1: #157fe1; //active menu line gradient
$color1-b2: #e4f1ff; //counter title back
$color1-c: #e4f1ff;

$color2-a: #e93e3e;
$color2-b: #ff5046;
$color2-c: #ffe7e9;

$color3-a: #18b32c;
$color3-b: #54cb64;
$color3-c: #e5f7e8;

$color4-a: #ff9100;
$color4-b: #f7bf47;
$color4-c: #fef5e3;

$color5-a: #0d163a;
$color5-b: #818698;
$color5-c: #dce0e6;
$color5-d: #f5f6f7;

$color6-a: #000000;

$loaderBack: #818698;
$loaderProgressLine: #54cb64;

$inputSize: 36px;
$inputIcon: #94bbf5;
$inputError: $color2-b;
$inputBorderColor: #dce0e6;
$inputTextColor: #646464;
$inputValueTextColor: #444444;
$inputDisabledBackColor: rgba(220, 224, 230, 0.3);

$radioBorder: #c8d0de;
$radioCheckedBorder: $color1-b;

$formInputTitle: #494969;
$formInputDefaultContent: #000000;
$formInputContentTitle: #696969;

$buttonDefault: #7e7e7e;
$buttonDefaultBack: #e2efff;
$buttonDefaultBorder: #daebff;
$buttonDefaultActiveBorder: #4da1ff;

$tableBorder: #dce0e6;
$tableHeadCell: #888888;
$tableHead: #f7f8fa;
$tableBack: $color-white;
$tableStrechBack: #fdfdfe;
$tableContentCell: rgba(68, 68, 68, 1);
$tableContentHover: rgba(228, 241, 255, 0.5);

$shadow-level-1: 0px 0px 0px 1px #e6eaee;
$shadow-level-2: 0px 1px 4px 0px rgba(8, 35, 48, 0.24);
$shadow-level-3: 0px 2px 8px 2px rgba(0, 0, 0, 0.4);
$shadow-level-4: 0px 8px 8px 0px rgba(8, 35, 48, 0.4);
$shadow-level-5: 0px 16px 24px 0px rgba(8, 35, 48, 0.16);
$shadow-level-6: 0px 24px 32px 0px rgba(8, 35, 48, 0.12);
$shadow-error: 0px 2px 8px 0px $color2-b;

$shadow-btn-sidebar-noData: -100px 0px 35px 0px rgba(119, 119, 119, 0.642);
$shadow-btn-sidebar-work: -100px 0px 35px 0px rgba(66, 245, 42, 0.642);
$shadow-btn-sidebar-inSleep: -100px 0px 35px 0px rgba(2, 88, 187, 0.753);
$shadow-btn-sidebar-offSleep: -100px 0px 35px 0px rgba(78, 157, 247, 0.642);
$shadow-btn-sidebar-charging: -100px 0px 35px 0px rgba(245, 198, 42, 0.642);
$shadow-btn-sidebar-poweredFrom: -100px 0px 35px 0px rgba(255, 180, 68, 0.642);
$shadow-btn-sidebar-alarm: -100px 0px 35px 0px rgba(245, 42, 42, 0.642);
$shadow-btn-sidebar-multipleselected: -100px 0px 35px 0px rgba(2, 88, 187, 0.753);
$shadow-btn-sidebar-not-multipleselected: -100px 0px 35px 0px rgba(119, 119, 119, 0.642);

$tooltipBack: #222c3c;
$tooltipColorTitle: #f0f3f8;
$tooltipColorCont: $color-white;
$tooltipDash: #323f53;

$back: #e5e5e5;
$backFilter: #fcfcfd;

$textColor: #202020;
$textColor-2: #a4a4a4;

$userStatePosition: #888888;
$dropDownArrow: #a4a4a4;
$dropDownItem: #444444;
$dropDownItemHover: #fafafa;

$selectPlaceholder: #888888;

$menuItem: #444444;
$menuItemActive: #1f78d1;

$horizontalLineColor: #dce0e6;
$titleBar: #444444;

$buttonSearchEmployees: #94bbf5;

$paginationBorder: #dce0e6;
$paginationColor: #7a7a7a;
$paginationColorActive: #1f78d1;
$paginationArrow: #888888;
$paginationArrowDisabled: #c4c4c4;
$paginationColorActiveBorder: #c4c4c4;
$paginationColorActiveBack: rgba(0, 0, 0, 0.1);
$paginationScrollBar: #888888;
$paginationScrollBarDefault: #dddddd;

$multiSelectFocusOption: #fafafa;
$multiSelectColorOption: #a4a4a4;
$multiSelectFocusColorOption: #444444;
$multiSelectLabel: #444444;
$multiSelectLabelBack: #f5f6f7;

$singleSelectFocusOption: #fafafa;
$singleSelectColorOption: #a4a4a4;
$singleSelectFocusColorOption: #444444;

$checkboxBack: #dee4ea;
$checkboxHoverBack: $color5-b;
$checkboxActiveBack: $color3-b;
$checkboxHoverActiveBack: $color3-a;
$checkboxItem: $color-white;

$paginationScrollBarHover: rgb(41, 31, 31);

$buttonSideBarToggle: #80868b;
$buttonIconColor: #80868b;
$countOnlist: #888888;

$deviceItemBack: #fdfdfe;
$deviceItemBorder: #e6eaee;
$deviceItemIcon: #e6eaee;
$deviceItemTitle: #696969;
$deviceItemContent: #444444;

$employeeItemBorderColor: #e6eaee;
$employeeTitle: #444444;
$userTitle: #444444;
$notIsBookmarkOnEmployees: #dce0e6;

$colllapsibleHeader: #444444;

$navbarTitleItem: #444444;

$animateTransition: 0.3s;

$minHeight: 600px;
$minHeightSides: calc(100vh - 68px);

$sideWidth: 400px;
$contentWidth: calc(100% - 480px);

$modalTitle: #444444;
$closeModalButton: #80868b;
$modalBorders: #dce0e6;

$intellegenceItemBack: #fdfdfe;
$intellegenceItemBorder: #e6eaee;
$inteleggenceParam: #696969;
$intellegenceData: #444444;

$datepickerColor: $color1-a;
$datepickerHoverColor: $color1-c;
$datepickerTextColor: #444444;

$preloaderColor: $color1-a;
$preloaderLightColor: $color-white;

$playerSpeed: 0.5s;

:export {
  multiSelectFocusOption: $multiSelectFocusOption;
  multiSelectColorOption: $multiSelectColorOption;
  multiSelectFocusColorOption: $multiSelectFocusColorOption;
  multiSelectLabelBack: $multiSelectLabelBack;
  multiSelectLabel: $multiSelectLabel;
  multiDropDownArrow: $dropDownArrow;
  inputError: $inputError;
  inputIcon: $inputIcon;
  radioBorder: $radioBorder;
  radioCheckedBorder: $radioCheckedBorder;
  animateTransition: $animateTransition;
  datepickerColor: $datepickerColor;
  datepickerHoverColor: $datepickerHoverColor;
  datepickerTextColor: $datepickerTextColor;
  preloaderColor: $preloaderColor;
  preloaderLightColor: $preloaderLightColor;
  inputBorderColor: $inputBorderColor;
  selectPlaceholder: $selectPlaceholder;
  inputValueTextColor: $inputValueTextColor;
  singleSelectFocusOption: $singleSelectFocusOption;
  singleSelectColorOption: $singleSelectColorOption;
  singleSelectFocusColorOption: $singleSelectFocusColorOption;
  dropDownArrow: $dropDownArrow;
  intellegenceItemBorder: $intellegenceItemBorder;
  employeeItemBorderColor: $employeeItemBorderColor;
  employeeTitle: $employeeTitle;
  textColor-2: $textColor-2;
  shadow-btn-sidebar-noData: $shadow-btn-sidebar-noData;
  shadow-btn-sidebar-work: $shadow-btn-sidebar-work;
  shadow-btn-sidebar-inSleep: $shadow-btn-sidebar-inSleep;
  shadow-btn-sidebar-work: $shadow-btn-sidebar-offSleep;
  shadow-btn-sidebar-work: $shadow-btn-sidebar-charging;
  shadow-btn-sidebar-work: $shadow-btn-sidebar-poweredFrom;
  shadow-btn-sidebar-work: $shadow-btn-sidebar-alarm;
  shadow-btn-sidebar-multipleselected: $shadow-btn-sidebar-multipleselected;
  shadow-btn-sidebar-not-multipleselected: $shadow-btn-sidebar-not-multipleselected;
  playerSpeed: $playerSpeed;
}
