@import './../base.scss';

.sideBarInner {
  padding: 10px 15px 5px;
  border-bottom: solid 1px $horizontalLineColor;

  &:last-child {
    border-bottom: 0px;
    padding-bottom: 0px
  }
}

@media (max-width: 1700px) {
  .sideBarInner {
    padding: 20px;
  }
}