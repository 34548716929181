@import './../base.scss';

.navigationItem{
  @include font(0.8125rem, 1.5rem,$menuItem, null,null);
  text-transform: uppercase;
  padding: 22px 0px;
  position: relative;
  display: block;
  &:before{
    content:"";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    margin-left: 350px;
    background-color: $menuItemActive;
    top: -3px;
    @include border-radius(0px 0px 2px 2px);
    @include linear-gradientFull(181.78deg, $color1-b1, -2.7%, $color1-b, 84.64%)
  }
  &:hover{
    color: $menuItemActive;
  }
}
.navigationItem_active{
  @extend .navigationItem;
  color: $menuItemActive;
  &:before{
    top: 0px;
  }
}