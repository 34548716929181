@import "./../base.scss";

.container_contentInner {
  & + .container_contentInner {
    margin-top: 5px;
    padding: 0px;
  }
}

@media (max-width: 1700px) {
  .container_contentInner {
    & + .container_contentInner {
      margin-top: 20px;
      padding-top: 20px;
    }
  }
}
