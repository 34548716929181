@import "./../../../src/base.scss";


.simpleClusterRoute {
  padding-top: 4px;
  text-align: center;
  font-size: 15px;
  background-color:  $color1-a;
  color: #ffffff;
  @include border-radius(50%);
  filter: drop-shadow(1px 1px 2px $color1-a);
  border-width: 3px;
  border-style: solid;
  border-color: hsla(100, 100%, 100%, 0.85);
}

.simpleCluster {
  padding-top: 3px;
  text-align: center;
  font-size: 13px;
  background-color:  $color1-a;
  color: #ffffff;
  @include border-radius(50%);
  filter: drop-shadow(1px 1px 2px $color1-a);
  border-width: 3px;
  border-style: solid;
  border-color: hsla(100, 100%, 100%, 1);
}

.simpleButtonCluster {
  padding-top: 0px;
  text-align: center;
  font-size: 12px;
  margin-right: 10px;
  background-color: #3b8f7d;
  color: #ffffff;
  @include border-radius(50%);
  filter: drop-shadow(0px 0px 0px $color6-a);
  border-width: 2px;
  border-style: solid;
  border-color: #ffffff;
}

.alarmCluster {
  padding-top: 10px;
  text-align: center;
  font-size: 15px;
  background-color: $color2-a;
  color: #ffffff;
  @include border-radius(50%);
  filter: drop-shadow(1px 1px 6px $color6-a);
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff;
  animation: redWhite 0.7s infinite;
}

@keyframes redWhite {
  from {
    background-color: $color2-a;
  }
  to {
    background-color: white;
  }
}

.mapMarker {
  padding-top: 3px;
  background-image: url(/img/map-marker.png);
  background-size: 32px;
  @include back-img(null, center, no-repeat);
  filter: drop-shadow(1px 3px 3px $color6-a);
  @include transition(transform $playerSpeed);

  .inner {
    margin: 0px auto;
    width: 28px;
    height: 28px;
    border-width: 3px;
    border-style: solid;
    border-color: $color5-b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 0px;
    @include border-radius(50%);
    @include transition(border-color 0.3s);
  }

  &.noData {
    .inner {
      border-color: $color5-b;
    }
  }

  &.inSleep {
    .inner {
      border-color: $color1-a;
    }
  }

  &.work {
    .inner {
      border-color: $color3-b;
    }
  }

  &.charging {
    .inner {
      border-color: $color4-b;
    }
  }

  &.offSleep {
    .inner {
      border-color: $color1-b;
    }
  }

  &.alarm {
    .inner {
      border-color: $color2-a;
      animation: redWhite 0.7s infinite;
    }
  }

  &.poweredFrom {
    .inner {
      border-color: $color4-a;
    }
  }
}

.mapMarkerStop {
  padding-top: 4px;
  background-image: url(/img/map-marker.png);
  background-size: 35px;
  @include back-img(null, center, no-repeat);
  filter: drop-shadow(1px 3px 3px $color6-a);
  @include transition(transform $playerSpeed);

  .inner {
    padding-top: 2px;
    margin: 1.5px auto;
    width: 31px;
    height: 31px;
    border-width: 3px;
    border-style: solid;
    border-color: $color1-b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    font-weight: bold;
    color: black;
    line-height: 1px;

    @include border-radius(50%);
    @include transition(border-color 0.3s);
  }
}

.mapMarkerJamming {
  padding-top: 4px;
  background-image: url(/img/map-marker.png);
  background-size: 35px;
  @include back-img(null, center, no-repeat);
  filter: drop-shadow(1px 3px 3px $color6-a);
  @include transition(transform $playerSpeed);

  .inner {
    padding-top: 2px;
    margin: 1.5px auto;
    width: 31px;
    height: 31px;
    border-width: 3px;
    border-style: solid;
    border-color: $color1-a;
    display: flex;
    justify-content: center;
    align-items: center;    
    font-size: 28px;
    font-weight: bold;
    color: red;
    line-height: 1px;
/*     margin-left: 1px; */

    @include border-radius(50%);
    @include transition(border-color 0.3s);
  }
}

.mapMarkerButton {
  padding-top: 4px;
  @include back-img(null, center, no-repeat);
  filter: drop-shadow(2px 1px 1px $color5-a);
  @include transition(transform $playerSpeed);

  .inner {
    margin: 0px auto;
    width: 23px;
    height: 23px;
    border-width: 2px;
    border-style: solid;
    border-color: $color5-b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    // font-weight: bold;
    line-height: 1px;

    @include border-radius(50%);
    @include transition(border-color 0.3s);
  }

  &.cancel {
    .inner {
      border-color: $color1-b;
      background-color: black;
    }
  }

  &.one {
    .inner {
      border-color: $color1-b;
      background-color: black;
    }
  }

  &.two {
    .inner {
      border-color: $color1-b;
      background-color: black;
    }
  }

  &.on {
    .inner {
      border-color: $color3-b;
      background-color: black;
    }
  }

  &.off {
    .inner {
      border-color: $color5-b;
      background-color: black;
    }
  }

  &.reset {
    .inner {
      border-color: $color2-b;
      border-color: black;
    }
  }
}

@keyframes redWhite {
  from {
    background-color: $color2-a;
  }
  to {
    background-color: white;
  }
}
