@import './../../../base.scss';

.employeesPaginationSettingsPanel {
  display: flex;
  align-items: center;

  .container_count {}

  .container_viewCount {
    margin-left: auto;
  }
}