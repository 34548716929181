@import "./../../../base.scss";

.tableInPopup {
  max-width: 210px!important;
}

.thInPopup1 {
  align-content: center;
  min-width: 170px;
}

.thInPopup2 {
  align-content: center;
  min-width: 130px;
}

.thInPopup3 {
  align-content: flex-start;
  min-width: 430px;
}

.tdInPopup {
  border-bottom: 0.01px solid;
  padding: 1px;
  border-color: rgba(236, 236, 236, 0.20);
}