@import "./../../../base.scss";

.employeesIntellegence {
  width: 98%;
  .container_dateTimeRangeWrapper {
    & + .container_dateTimeWrapper {
      //margin-top: 10px;
    }
  }

  .container_dateTimeWrapper {
    // display: flex;
    // align-items: flex-end;

    .container_itemsTimeIntellegence {
      display: flex;
      align-items: flex-end;

      .container_itemsTimeButtom {
        margin-left: 20px;
      }

      & + .container_intellegenceTimeRangePlayer {
        //   margin-top: 10px;
      }
    }
  }

  .container_preloader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
