@import "./../../../base.scss";

.usersListItemWrapper {
  padding: 3px 0px 0px 15px;

  .linkWrapper {
    &:hover {
      cursor: pointer;
    }
  }

  .container_bookmark {
    position: absolute;
    display: block;
    top: 8px;
    right: 15px;
    @include font(1rem, 1rem, $notIsBookmarkOnEmployees, null, null);
    @include transition(color 0.3s);
    font-size: 0.85rem;
    line-height: 0.5rem;
    font-weight: 600;

    &:hover {
      color: $color1-a;
    }
  }

  .container_mSelect {
    position: absolute;
    display: block;
    top: 8px;
    right: 15px;
    @include font(1rem, 1rem, $notIsBookmarkOnEmployees, null, null);
    @include transition(color 0.3s);

    &:hover {
      color: $color1-a;
    }
  }

  .usersListItem {
    padding: 5px;

    .container_userNumber {
      position: absolute;
      width: 18px;
      height: 100%;
      left: 0px;
      top: 0px;

      .title {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -9px;
        margin-left: -40px;
        width: 80px;
        height: 18px;
        text-align: center;
        @include font(0.75rem, 18px, $color-white, 500, null);
        @include transform(rotate(-90deg));
      }
    }

    .container_userContent {
      padding: 5px;

      .container_name {
        padding: 5px;
        @include font(1.15rem, 1.2rem, $userTitle, 600, null);
      }
    }
  }
}

.usersListItem_isBookmark {
  @extend .usersListItem;

  .container_bookmark {
    color: $color2-b;

    &:hover {
      color: $color1-b;
    }
  }
}

.usersListItem_is_mSelect {
  @extend .usersListItem;

  .container_mSelect {
    color: $color2-b;

    &:hover {
      color: $color1-b;
    }
  }
}
