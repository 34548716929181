@import "./../../base.scss";

.sideHead {
  font-size: 12px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  background-color: $color-white;
  @include box-shadow($shadow-level-3);
  position: relative;
  z-index: 2;
}

.sideHeadAlarm {
  font-size: 12px;
  padding: 0px 30px;
  display: flex;
  align-items: center;

  @include box-shadow($shadow-level-3);
  position: relative;
  z-index: 2;
  animation: redWhite 0.7s infinite;
}

@keyframes redWhite {
  from {
    background-color: $color2-a;
  }
  to {
    background-color: white;
  }
}

.container_navigation {
  margin-left: 60px;
}

.container_userNotification {
  text-align: right;
  margin-left: auto;
}

@media (max-width: 1700px) {
  .sideHead {
    padding: 0px 40px;
  }

  .container_navigation {
    margin-left: 60px;
  }
}
