@import './../../base.scss';

.sideBar {
  height: 100%;
  @include box-shadow($shadow-level-3);
  background-color: $color-white;
  position: relative;
  z-index: 1;
  min-height: $minHeightSides;

  .container_title {
    position: absolute;
    z-index: 3;
    left: 0px;
    right: 0px;
    width: 65px;
    height: $minHeightSides;
    background-color: $color-white;
  }

  .container_sideBarInner {
    position: relative;
    z-index: 2;
    background-color: $color-white;
  }

  .buttonToggleOpen {
    position: absolute;
    z-index: 1;
    left: 100%;
    top: 0px;
    width: 24px;
    height: 24px;
    display: flex;
    align-content: center;
    justify-content: center;
    background-color: $color-white;
    border: 0px;
    outline: none;
    @include border-radius-bottom-right(4px);
    @include box-shadow($shadow-level-3);
    cursor: pointer;

    .container_sideBarIcon {
      display: block;
      @include font(0.5625rem, 22px, $buttonSideBarToggle, null, null)
    }
  }

  .buttonToggleOpen_barOpen {
    @extend .buttonToggleOpen;

    .container_sideBarIcon {
      @include transform(rotate(180deg));
    }
  }

  .buttonToggleOpen_barClosed {
    @extend .buttonToggleOpen;

    .container_sideBarIcon {
      @include transform(rotate(0deg));
    }
  }
}

.sideBar_open {
  @extend .sideBar;

  .container_title {
    left: -65px;
    opacity: 0;
    $transition: opacity $animateTransition, left $animateTransition $animateTransition;
    @include transition($transition);
  }
}

.sideBar_closed {
  @extend .sideBar;

  .container_title {
    opacity: 1;
    left: calc(400px - 65px);
    $transition: opacity $animateTransition, left 0s;
    @include transition($transition);
  }
}

@media (max-width: 1700px) {
  .sideBar_closed {
    .container_title {
      left: calc(320px - 65px);
    }
  }
}