@import "./../../../base.scss";

.intelligenceResultContent {
  .container_headControls {
    .container_exportControls {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .container_exportItem {
        margin-left: 10px;
      }
    }
    & + .container_viewTable {
      margin-top: 5px;
    }
  }
}
